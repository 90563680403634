import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "./chefListScreen.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { PREP_TIME, S3_MENU_FOLDER, S3_MENU_THUMBNAIL_FOLDER } from '../../store/constants';
import { getTodayDateValue, secondShiftApplies } from '../../utils/timeHelper';
import moment from 'moment';
import { menuActions } from '../../store/menuSlice';
import { bottomBarActions } from '../../store/bottomBarSlice';
import 'video.js/dist/video-js.css'
import LeafIconSvg from '../../assets/ReactSvg/LeafIconSvg';
import CustomVideoPlayer from '../CustomVideoPlayer/Index';
import ChefCardSkeleton from './ChefCardSkeleton';
import BackBtnSvg from '../../assets/ReactSvg/BackBtnSvg';

const ChefCardComponent = ({ chef, isChefMainScreen, chefVideo, setVideoLoadedIndex, videoLoadedIndex, chefCardComponentIndex }) => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.chef.categories);
    const [windowWidth, setWindowWidth] = useState(Math.min(window.innerWidth - 30, 550 - 30));
    const [imgWidth, setImgWidth] = useState(windowWidth);
    const [isReady, setIsReady] = useState(false);
    const [isVideoReadyToPlay, setIsVideoReadyToPlay] = useState(false);
    const deliveryDetails = useSelector(({ auth }) => auth.deliveryDetails);
    const isServiceable = useSelector((state) => state.auth.isServiceable);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(Math.min(window.innerWidth - 30, 550 - 30));
          };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
    const onChefClick = (chefInfo) => {
        const chefId = chefInfo._id;
        dispatch(menuActions.setCurrentChefIdClicked(chefId));
        dispatch(bottomBarActions.loadTab({ tabName: "chefListScreen" }));
    }

    const todayChefSchedule = getTodayDateValue(chef?.schedules);
    const kitchenId = chef?.kitchenId || "";
    const chefDeliveryDetails = deliveryDetails[kitchenId] || {};
    const delivery_distance = chefDeliveryDetails?.delivery_distance;
    const deliveryDistanceInKMS = Math.round(delivery_distance / 1000);

    const getChefDistance = () => {
        return `${deliveryDistanceInKMS} km`
    }

    const getStatusLabel = (chefInfo) => {
        const { isSwitchOff, chefWaitTime, schedules = [], kitchenId } = chefInfo;
        const chefDeliveryDetails = deliveryDetails[kitchenId] || {};
        const delivery_distance = chefDeliveryDetails?.delivery_distance;
        const delivery_duration = chefDeliveryDetails?.delivery_duration?.value;
        let delivery_Time = delivery_duration ? Math.round(delivery_duration / 60) : 10;

        const chefWaitTimeValue = Math.max(PREP_TIME, chefWaitTime || 0);
        const todayChefSchedule = getTodayDateValue(schedules);

        if (!isServiceable) {
            return "Pre-bookings only"
        } else if (isSwitchOff) {
            return "offline"
        } else if (!todayChefSchedule?.isAvailable) {
            const findNextDayIndex = schedules.findIndex((s) => s._id === todayChefSchedule._id);
            if (findNextDayIndex === (schedules.length - 1)) {
                const nextDayInfo = schedules[0];
                const startTime = nextDayInfo?.startTime || nextDayInfo?.startTime1;
                return `Opens tomorrow at ${startTime}`
            } else {
                const nextDayInfo = schedules[findNextDayIndex + 1];
                const startTime = nextDayInfo?.startTime || nextDayInfo?.startTime1;
                return `Opens tomorrow at ${startTime}`
            }
        } else if (todayChefSchedule?.online) {
            if (delivery_duration) {
                return `Delivery in ${delivery_Time + chefWaitTimeValue} mins`;
            } else {
                return "Open now";
            }
        } else if (todayChefSchedule?.isAvailable) {
            if (secondShiftApplies(todayChefSchedule) && todayChefSchedule?.startTime1 && todayChefSchedule?.endTime1) {
                return `Opens at ${moment(todayChefSchedule?.startTime1, 'h:mm A').format('h a')}`
            }
            return `Opens at ${moment(todayChefSchedule?.startTime, 'h:mm A').format('h a')}`
        }
    }

    const getStatusClassname = (todayChefSchedule) => {
        if (!isServiceable) {
            return "chef-non-serviciable";
        } else if (todayChefSchedule?.online) {
            return "chef-online";
        } else {
            return "chef-away";
        }
    };

    const ChefDetailInfoWrapper = () => (
        <div onClick={() => onChefClick(chef)} className={`chefDetailInfoWrapper ${isChefMainScreen ? "chefDetailInfoWrapperInner" : ""}`}>
            <div>
                <span style={isChefMainScreen ? { color: "#ffffff", fontWeight: 700, fontSize: 16 } : { color: "#000000", fontWeight: 700, fontSize: 16 }}>{chef.name}</span>
                <div style={{ lineHeight: "10px", display: "flex", columnGap: 5, alignItems: "center" }}>
                    <div style={{ borderRadius: "100rem", height: "9px", width: "9px" }} className={getStatusClassname(todayChefSchedule)}></div>
                    <span style={isChefMainScreen ? { color: "#ffffff", fontWeight: 300, fontSize: 12 } : { color: "#000000", fontWeight: 300, fontSize: 12 }}>{getStatusLabel(chef)}</span>
                    {!isNaN(deliveryDistanceInKMS) && <span className='chefDistanceLabel' style={isChefMainScreen ? { color: "#ffffff", fontWeight: 500, fontSize: 12 } : { color: "#000000", fontWeight: 500, fontSize: 12 }}>{getChefDistance(chef)}</span>}
                </div>
                {!isChefMainScreen && <div className='chefInfoWrapperDescription'>
                    <span><LeafIconSvg /> {`${chef?.label1}`}</span>
                    <span><LeafIconSvg /> {`${chef?.label2}`}</span>
                    <span><LeafIconSvg /> {`${chef?.label3}`}</span>
                </div>}
            </div>
            {/* <div className='chefLeftInfoDesc'>
                <span className='successRateLabel'>
                    <StarSvg />
                    {chef?.label2}
                </span>
                <span style={isChefMainScreen ? { color: "#fff" } : {}} className='sucessDishesCountLabel'>
                    {`${chef?.label3}`}
                </span>
            </div> */}
        </div>
    )

    const switchTab = (tabName) => {
        dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    };

    const onBackClick = () => {
        switchTab("chef");
    };

    const dishesImage = categories[chef._id];
    const cardImages = [chef.profileImg];
    const dishesArrayOfArray = dishesImage?.map((category) => category.dishes.map((dish) => dish.stories.map((story) => story.img)));
    const storyArray = [].concat.apply([], dishesArrayOfArray);
    const dishArrayImages = [].concat.apply([], storyArray).map((img) => S3_MENU_FOLDER + img);
    const cardImagesArray = [...cardImages, ...dishArrayImages];
    const slicecardImagesArray = cardImagesArray.slice(0, 1) || [];

    const onChange = () => { };

    const onClickItem = () => { };

    const onClickThumb = () => { };

    const chefVideoUrl = S3_MENU_FOLDER + chefVideo;

    // const CarouselArray = chefVideoUrl && !isChefMainScreen ? [chefVideoUrl, ...slicecardImagesArray] : [...slicecardImagesArray];
    const CarouselArray = [...slicecardImagesArray];

    const onChefVideoReady = () => {
        setIsReady(true)
    }

    useEffect(() => {
        onChefVideoReady()
    }, [])

    const handleSetIsVideoReadyToPlay = (value) => {
        // if(videoLoadedIndex <= chefCardComponentIndex){
        //     setVideoLoadedIndex(chefCardComponentIndex + 1);
        // }
        setIsVideoReadyToPlay(value)
    }
    useEffect(() => {
        // if (isChefMainScreen) {
        //     handleSetIsVideoReadyToPlay(true)
        // }
        if (true) {
            handleSetIsVideoReadyToPlay(true)
        }
    }, [isChefMainScreen])

    const allCuisineList = chef?.cuisine.split(",")

    return (
        <>
            {!isVideoReadyToPlay && <ChefCardSkeleton />}
            <div style={{paddingBottom: 10, marginBottom: 15}} className={`chefCardWrapper ${isVideoReadyToPlay ? 'show' : 'hide'}`}>
                <div className='chefCardWrapper' style={isChefMainScreen ? { marginBottom: 10 } : { marginBottom: 0 }}>
                    <div className='carouselOverlayContentWrapper'>
                        <div>
                            <Carousel
                                preventMovementUntilSwipeScrollTolerance={true}
                                showArrows={false}
                                showThumbs={false}
                                showStatus={false}
                                // showIndicators={!isChefMainScreen}
                                showIndicators={false}
                                swipeScrollTolerance={100}
                                className='slider-custom-carousel'
                                onChange={onChange}
                                destroyCarousel
                                onClickItem={onClickItem}
                                onClickThumb={onClickThumb}
                            >
                                {CarouselArray.map((img, index) => (
                                    <div key={index}>
                                        {img.includes(".mp4") ? (
                                            <div className='carousel-react-player'>
                                                <CustomVideoPlayer
                                                    videoUrl={img}
                                                    height={imgWidth}
                                                    onReady={onChefVideoReady}
                                                    index={index}
                                                    handleSetIsVideoReadyToPlay={handleSetIsVideoReadyToPlay}
                                                />
                                                <div onClick={() => onChefClick(chef)} className='chefPhotoClickDiv'>
                                                </div>
                                            </div>
                                        ) : (
                                            <img style={{ height: imgWidth, objectFit: "cover" }} alt="" src={img.includes("amazonaws.com") ? img : S3_MENU_THUMBNAIL_FOLDER + img} />
                                        )}
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        {isChefMainScreen && <div className='carouselTopBackbtn' onClick={onBackClick} ><BackBtnSvg fill="#fff" /></div>}
                        <div className={`carouselOverlayContent ${isChefMainScreen && "carouselOverlayContentMain"}`}>
                            {allCuisineList?.map((cuisine) =>(
                                <span className='carouselOverlayCuisineName'>
                                {cuisine?.trim()?.toUpperCase()}
                            </span>
                            ))}
                        </div>
                        {isChefMainScreen && <ChefDetailInfoWrapper />}
                        {!isChefMainScreen && (
                            <div style={{ left: "-10px", textAlign: "end" }} className='carouselOverlayContent'>

                            </div>
                        )}
                    </div>
                    {!isChefMainScreen && <ChefDetailInfoWrapper />}
                    {!isChefMainScreen && (
                        <div onClick={() => onChefClick(chef)} className='chefCardDiscountLabelWrapper'>
                            <div style={{ fontWeight: 700 }}>
                                UPTO <span style={{ color: "#1EB36B" }}>15% OFF</span> ON WEEKLY MEALS
                            </div>
                            <div>Paneer Tikka Paneer Tikka Paneer Tikka Paneer Tikka</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ChefCardComponent;
