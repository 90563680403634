import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderStatus: "",
  estimatedTime : 0,
  driverName : "",
  deliveredDate : "",
  driverPhoneNumber : "",
  orderFulfillment : {},
  driverLocation : {}
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    changeOrderStatus(state, action) {
      state.orderStatus = action.payload.status;
      state.driverName = action.payload.driverName;
      state.driverPhoneNumber = action.payload.driverPhoneNumber;
      state.orderFulfillment = action.payload.orderFulfillment;
      state.deliveredDate = action.payload.deliveredDate
      return state;
    },
    changeEstimatedTime(state, action) {
      state.estimatedTime = action.payload.time;
      return state;
    },
    deliveryDriverLocation(state, action) {
      state.driverLocation = action.payload?.data?.data || {};
      return state;
    },
  },
});

export const orderActions = orderSlice.actions;

export default orderSlice;
