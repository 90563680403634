import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import "./orderStatus-badge.css";
import { useEffect, useState } from "react";
import { orderActions } from "../../store/orderSlice";
import { API_TEST, ORDER_EXPIRY_TIME, PIDGE_API_TOKEN, PIDGE_API_URL, SHADOWFAX_API_TOKEN, SHADOWFAX_API_URL } from "../../store/constants";
import { loginActions } from "../../store/loginSlice";
import { fetchDriverLocation } from "../../store/orderActions";
import moment from "moment";
import axios from "axios";
import { updateOrderAction } from "../../store/loginActions";

const OrderStatusBadge = (props) => {
  const { visible } = props
  const dispatch = useDispatch();
  const [intervalRef, setIntervalRef] = useState(null);
  const activeOrderDetails = useSelector((state) => state.auth.orderDetails);
  const orderDeliveredDate = useSelector(
    (state) => state.order.deliveredDate
  );
  const singleOrderHistoryDetails = useSelector((state) => state.auth.singleOrderHistoryDetails);
  const orderDetails = !!singleOrderHistoryDetails ? singleOrderHistoryDetails : activeOrderDetails
  const orderStatusReducer = useSelector((state) => state.order.orderStatus);
  const estimatedTimeReducer = useSelector((state) => state.order.estimatedTime);
  const switchTab = (tabName) => {
    props.orderType == "order-now" ?
      dispatch(bottomBarActions.loadTab({ tabName: "orderSummary" }))
      : dispatch(bottomBarActions.loadTab({ tabName: "dishBook" }));
  };

  const getOrderStatus = () => {
    switch (orderStatusReducer) {
      case "CREATED":
        return "Order Placed";
      case "CONFIRMED":
        return "Order Confirmed";
      case "PICKED_UP":
      case "OUT_FOR_DELIVERY":
      case "live":
        return "Order on the way";
      case "UNDELIVERED":
        return "Can not deliver";
      case "RTO_OUT_FOR_DELIVERY":
        return "Kitchen Return - En Route";
      case "RTO_DELIVERED":
        return "Delivered to kitchen";
      case "REACHED_DELIVERY":
        return "Rider arrived";
      case "READY":
        return "Order is ready.";
      case "DELIVERED":
      case "completed":
      case "ended":
        return "Your order is delivered.";
      case "CANCELLED":
        return "Your order is cancelled.";
      case "ALLOTTED":
        return "Your order's rider is allotted.";
      case "ACCEPTED":
        return "Rider accepted the order.";
      case "ARRIVED":
        return "Rider arrived to pick up.";
      case "COLLECTED":
        return "Rider collected the order.";
      case "ARRIVED_AT_CUSTOMER_DOORSTEP":
        return "Rider arrived at the doorstep.";
      case "RTS_INITIATED":
        return "Your order returning to kitchen.";
      case "RTS_COMPLETED":
        return "Your order returned to kitchen.";
      case "cancelled":
        return "Rider cancelled order.";
      default:
        return "Your order is in progress.";
    }
  };

  const isOrderDeliveredMoreThan2HoursAgoFun = (orderDeliveredDate) => {
    const currentMoment = moment();
    const orderDeliveredMoment = moment(orderDeliveredDate);
    const differenceInMinutes = currentMoment.diff(orderDeliveredMoment, "minutes");

    return differenceInMinutes > 120; // 2 hours = 120 minutes
  };

  const isBadgeVisible = () => {
    if (orderDetails?.createdAt && orderDetails?.orderType == "order-now") {
      let { createdAt, updatedAt } = orderDetails;
      let orderedDate = new Date(updatedAt ? updatedAt : createdAt);
      orderedDate.setTime(
        orderedDate.getTime() + ORDER_EXPIRY_TIME * 60 * 60 * 1000
      );
      orderedDate = orderedDate.toLocaleString();
      let curDate = new Date();
      curDate = curDate.toLocaleString();

      if (orderedDate < curDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const pidgeOrderDetails = async (orderId, pidge_order_id) => {
    try {
      const orderResponse = await fetch(API_TEST + `getOrderStatus/${orderId}`);
      const orderJsonData = await orderResponse.json();
      const orderStatus = orderJsonData.data.status;
      const apiUrl = `${PIDGE_API_URL}/v1.0/store/channel/vendor/order/${pidge_order_id}/`;
      const token = PIDGE_API_TOKEN
      const config = {
        headers: {
          Authorization: `${token}`
        }
      };
      const response = await axios.get(apiUrl, config);
      const jsonData = response.data.data;
      const driverName = jsonData?.fulfillment?.rider?.name || "";
      const driverPhoneNumber = jsonData?.fulfillment?.rider?.mobile || "";
      const deliveredDate = "";
      const pidgeStatus = jsonData?.fulfillment?.status;
      const isValidStatus = !!pidgeStatus && !["CREATED", "OUT_FOR_PICKUP", "REACHED_PICKUP"].includes(pidgeStatus)
      const status = isValidStatus ? pidgeStatus : orderStatus;
      dispatch(orderActions.changeOrderStatus({ status: status, driverName, driverPhoneNumber, deliveredDate, orderFulfillment: jsonData?.fulfillment || {} }));
      if (isValidStatus) {
        dispatch(updateOrderAction(orderDetails?._id, { status: status }))
      }
      // TO:DO - Stop interval without refresh
      // if(orderDetails.status != status){
      //   let newOrderDetails = { ...orderDetails };
      //   newOrderDetails.status = status;
      //   dispatch(loginActions.setOrderDetails(newOrderDetails));
      // }
      // if(status == "DELIVERED"){
      //   clearInterval(stateInterval);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const shadowfaxOrderDetails = async (orderId, pidge_order_id) => {
    try {
      // const response = await fetch(API_TEST + `getOrderStatus/${orderId}`);
      const apiUrl = `${SHADOWFAX_API_URL}track/${orderId}/`; // Your token
      const token = SHADOWFAX_API_TOKEN

      const config = {
        headers: {
          Authorization: `${token}`
        }
      };
      const response = await axios.get(apiUrl, config);
      const jsonData = response.data;
      const status = jsonData.status;
      const driverName = jsonData.rider_name || "";
      const driverPhoneNumber = jsonData.rider_contact_number || "";
      const deliveredDate = "";
      dispatch(orderActions.changeOrderStatus({ status: status, driverName, driverPhoneNumber, deliveredDate }));
      if (true) {
        dispatch(updateOrderAction(orderDetails?._id, { status: status }))
      }
      // TO:DO - Stop interval without refresh
      // if(orderDetails.status != status){
      //   let newOrderDetails = { ...orderDetails };
      //   newOrderDetails.status = status;
      //   dispatch(loginActions.setOrderDetails(newOrderDetails));
      // }
      // if(status == "DELIVERED"){
      //   clearInterval(stateInterval);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const porterOrderDetails = async (orderId, pidge_order_id) => {
    try {
      const orderResponse = await fetch(API_TEST + `getOrderStatus/${orderId}`);
      const orderJsonData = await orderResponse.json();
      const orderStatus = orderJsonData.data.status;
      const apiUrl = `${API_TEST}porter/orders/${pidge_order_id}`;
      const response = await axios.get(apiUrl);
      const jsonData = response.data.data;
      const driverName = jsonData?.partner_info?.name || "";
      const driverPhoneNumber = jsonData?.partner_info?.mobile?.mobile_number || "";
      const deliveredDate = "";
      const pidgeStatus = jsonData?.status;
      const isValidStatus = !!pidgeStatus && !["open", "accepted"].includes(pidgeStatus)
      const status = isValidStatus ? pidgeStatus : orderStatus;
      dispatch(orderActions.changeOrderStatus({ status: status, driverName, driverPhoneNumber, deliveredDate, orderFulfillment: jsonData?.fulfillment || {} }));
      if (isValidStatus) {
        dispatch(updateOrderAction(orderDetails?._id, { status: status }))
      }
      // TO:DO - Stop interval without refresh
      // if(orderDetails.status != status){
      //   let newOrderDetails = { ...orderDetails };
      //   newOrderDetails.status = status;
      //   dispatch(loginActions.setOrderDetails(newOrderDetails));
      // }
      // if(status == "DELIVERED"){
      //   clearInterval(stateInterval);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      // const orderId = orderDetails?._id;
      const pidge_order_id = orderDetails.pidge_order_id;
      const orderId = orderDetails?._id;
      const selectedDeliveryPortal = orderDetails.selectedDeliveryPortal;
      // dispatch(fetchDriverLocation(orderId));
      if (estimatedTimeReducer !== 0) {
        dispatch(orderActions.changeEstimatedTime({ time: estimatedTimeReducer - 1 }));
      } else if (estimatedTimeReducer === 0) {
        dispatch(orderActions.changeEstimatedTime({ time: orderDetails.deliveryTime || 0 }));
      }
      switch (selectedDeliveryPortal) {
        case "PIDGE":
          pidgeOrderDetails(orderId, pidge_order_id)
          break;
        case "SHADOWFAX":
          shadowfaxOrderDetails(orderDetails.orderRef, pidge_order_id)
          break;
        case "PORTER":
          porterOrderDetails(orderId, pidge_order_id)
          break;
        default:
          break;
      }
    };

    // Clear the previous interval when orderDetails?._id changes
    if (intervalRef) {
      clearInterval(intervalRef);
    }

    if (!!singleOrderHistoryDetails ? true : (orderDetails?._id && isBadgeVisible())) {
      fetchData();
      // Start a new interval and store the reference
      const interval = setInterval(fetchData, 20000);
      setIntervalRef(interval);
      return () => clearInterval(interval);
    }
  }, [orderDetails?._id]);

  const isOrderDeliveredMoreThan2HoursAgo = !!orderDeliveredDate && moment(orderDeliveredDate).isValid() && isOrderDeliveredMoreThan2HoursAgoFun(orderDeliveredDate);

  if (!visible || isOrderDeliveredMoreThan2HoursAgo) return <></>

  return (
    <div
      className="order_status-badge"
      onClick={() => switchTab("orderSummary")}
    >
      {props.orderType == "order-now" ? (
        <><p>{getOrderStatus()}</p>
          <span>View</span></>
      ) : (
        <><p>You have a pre-order</p>
          <span>Schedule</span></>
      )}
    </div>
  );
};

export default OrderStatusBadge;
