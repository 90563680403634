import axios from "axios";
import { bottomBarActions } from "./bottomBarSlice";
import { cartAction } from "./cartSlice";
import { chefActions } from "./chefSlice";
import {
  API_TEST,
  MSG91_AUTH_KEY,
  MSG91_SMS_CHECKOUT_TEMPLATE_ID,
  MSG91_SMS_SENDER_ID,
  MSG91_SMS_TEMPLATE_ID,
  MSG91_URL,
  PIDGE_API_TOKEN,
  PIDGE_API_URL,
  SHADOWFAX_API_TOKEN,
  SHADOWFAX_API_URL,
} from "./constants";
import { loginActions } from "./loginSlice";
import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";
import { applyProportionalDiscount } from "../utils/helper";
// import clevertap from "clevertap-web-sdk";

export const registeration = (user, setError, chefId) => async (dispatch) => {
  try {
    const { data } = await axios.post("user", user);
    if (data.statusCode == 200) {
      const userData = {
        userid: data.data._id,
        username: data.data.name,
        phonenumber: data.data.phoneNumber,
        menuOpens: data.data.menuOpens,
        menuIdsOpened: data.data.menuIdsOpened,
        dishPriceChecked: data.data.dishPriceChecked,
        dishIdsChecked: data.data.dishIdsChecked,
        exploredDishesIds: data.data.exploredDishesIds,
        checkedDishesInfoIds: data.data.checkedDishesInfoIds,
        pausedDishesIds: data.data.pausedDishesIds,
        email: data.data.email,
        area: data.data.area,
        referral: data.data.referral,
        referralCode: data.data.referralCode,
        maxDishes: data.data.maxDishes,
        maxChefs: data.data.maxChefs,
        maxDishPrices: data.data.maxDishPrices,
        totalDishesBooked: data.data.totalDishesBooked,
        freeDishes: data.data.freeDishes,
        type: data.data.type,
        addressLine1: data.data.addressLine1,
      };
      if (data.data?.firebaseToken) {
        userData.firebaseToken = data.data?.firebaseToken
      }
      if (data.data.statusCode == 405) {
        dispatch(loginActions.setGetReferral());
        setError(true);
      } else if (data.data.name) {
        dispatch(loginActions.setLoggedIn({ loggedIn: true, userData }));
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        mixpanel.track("login_successful");
        // clevertap.event.push("login_successful");
        let mpUserId = localStorage.getItem("mpUserId");
        if (!(mpUserId === userData.userid)) {
          mixpanel.alias(userData.userid, mpUserId);
          mixpanel.people.set({
            name: userData.username,
            phoneNumber: userData.phonenumber,
          });
        }
        if (chefId) {
          dispatch(bottomBarActions.updateFrom({ from: "login" }));
        }
      } else {
        dispatch(loginActions.setGetName({ userData }));
      }

    } else if (data.statusCode == 400) {
      console.log("error");
      //TODO: show error page
    }
  } catch ({ ...error }) {
    dispatch(loginActions.setLoginError({ loggedIn: false, error }));
  }
};

export const login = (user, chefId) => async (dispatch) => {
  try {
    const { data } = await axios.post("user/login", user);
    if (data.statusCode == 200) {
      const userData = {
        userid: data.data._id,
        username: data.data.name,
        phonenumber: data.data.phoneNumber,
        menuOpens: data.data.menuOpens,
        menuIdsOpened: data.data.menuIdsOpened,
        dishPriceChecked: data.data.dishPriceChecked,
        dishIdsChecked: data.data.dishIdsChecked,
        exploredDishesIds: data.data.exploredDishesIds,
        checkedDishesInfoIds: data.data.checkedDishesInfoIds,
        pausedDishesIds: data.data.pausedDishesIds,
        email: data.data.email,
        area: data.data.area,
        referral: data.data.referral,
        referralCode: data.data.referralCode,
        maxDishes: data.data.maxDishes,
        maxChefs: data.data.maxChefs,
        maxDishPrices: data.data.maxDishPrices,
        totalDishesBooked: data.data.totalDishesBooked,
        type: data.data.type,
        addressLine1: data.data.addressLine1,
      };
      if (data.data?.firebaseToken) {
        userData.firebaseToken = data.data?.firebaseToken
      }
      if (data.data.name) {
        dispatch(loginActions.setLoggedIn({ loggedIn: true, userData }));
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        mixpanel.track("login_successful");
        // clevertap.event.push("login_successful");
        let mpUserId = localStorage.getItem("mpUserId");
        if (!(mpUserId === userData.userid)) {
          mixpanel.alias(userData.userid, mpUserId);
          mixpanel.people.set({
            name: userData.username,
            phoneNumber: userData.phonenumber,
          });
        }
        if (chefId) {
          dispatch(bottomBarActions.updateFrom({ from: "login" }));
        }
      } else {
        dispatch(loginActions.setGetName({ userData }));
        mixpanel.track("login_successful-new_signup");
        // clevertap.event.push("login_successful-new_signup");
      }

    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    dispatch(loginActions.setLoginError({ loggedIn: false, error }));
  }
};

export const getOpenPaymentToken = (paymentId, amount, phonenumber) => async (dispatch) => {
  const data = await axios.get('getOpenPaymentToken/' + paymentId + "/" + amount + "/" + phonenumber);
  dispatch(loginActions.setOpenPaymentToken({ data }));
}

export const reloadUser = (userId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get("user/" + userId);
      if (response.status != 200) {
        throw new Error("Could not fetch User");
      }
      const data = response.data;
      const userData = {
        userid: data.data._id,
        username: data.data.name,
        phonenumber: data.data.phoneNumber,
        menuOpens: data.data.menuOpens,
        menuIdsOpened: data.data.menuIdsOpened,
        dishPriceChecked: data.data.dishPriceChecked,
        dishIdsChecked: data.data.dishIdsChecked,
        exploredDishesIds: data.data.exploredDishesIds,
        checkedDishesInfoIds: data.data.checkedDishesInfoIds,
        pausedDishesIds: data.data.pausedDishesIds,
        email: data.data.email,
        area: data.data.area,
        referral: data.data.referral,
        referralCode: data.data.referralCode,
        maxDishes: data.data.maxDishes,
        maxChefs: data.data.maxChefs,
        maxDishPrices: data.data.maxDishPrices,
        totalDishesBooked: data.data.totalDishesBooked,
        freeDishes: data.data.freeDishes,
        type: data.data.type,
        addressLine1: data.data.addressLine1,
      };
      if (data.data?.firebaseToken) {
        userData.firebaseToken = data.data?.firebaseToken
      }
      return userData;
    };

    try {
      const userData = await fetchData();
      dispatch(loginActions.setLoggedIn({ loggedIn: true, userData }));
      if (!userData.username) {
        dispatch(loginActions.setGetName({ userData }));
      }
    } catch (error) {
      console.log("error while calling user-fetchdata");
      // show error on ui
    }
  };
};

export const setBookingData = (userId) => async (dispatch) => {
  try {
    if (userId) {
      const { data } = await axios(`bookings/${userId}`);
      if (data.data) dispatch(loginActions.setBooking({ booking: data.data }));
    }
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const getPreLaunchOrder = (userId) => async (dispatch) => {
  try {
    if (userId) {
      const { data } = await axios(`order/active/${userId}`);
      if (data.data.length > 0) dispatch(loginActions.setOrderDetails(data.data[0]));
    }
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const setAddressData =
  (userId, newAddress, isOrderUpdate) => async (dispatch) => {
    try {
      if (userId) {
        let address = newAddress;
        const { data } = await axios.post(`address/${userId}`, address);
        if (data.statusCode === 200) {
          dispatch(loginActions.setAddress(address));
          isOrderUpdate
            ? dispatch(bottomBarActions.loadTab({ tabName: "checkout" }))
            : dispatch(bottomBarActions.loadTab({ tabName: "cart" }));
        }
      }
    } catch ({ ...error }) {
      console.log(error);
    }
  };

export const setAddressForPreOrder =
  (userId, newAddress) => async (dispatch) => {
    try {
      if (userId) {
        let address = newAddress;
        const { data } = await axios.post(`address/${userId}`, address);
        if (data.statusCode === 200) {
          dispatch(loginActions.setAddress(address));
        }
      }
    } catch ({ ...error }) {
      console.log(error);
    }
  };

export const addWaitingList =
  (phoneNumber, enableButton) => async (dispatch) => {
    try {
      if (phoneNumber) {
        let user = { phoneNumber: phoneNumber };
        const { data } = await axios.post(`user/waiting`, user);
        if (data.data.statusCode == 405) {
          dispatch(loginActions.setWaitListFail());
          enableButton();
        } else {
          dispatch(loginActions.setWaitListSuccess());
          enableButton();
        }
      }
    } catch ({ ...error }) {
      console.log(error);
    }
  };
export const getUserCount = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`user/getUserCount`);
    dispatch(loginActions.setUserCount(data.data));
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const updateName = (user, name) => async (dispatch) => {
  try {
    const { data } = await axios.post("userName/" + user.userid, {
      name: name,
    });
    if (data.statusCode == 200) {
      dispatch(loginActions.setName({ name: name }));
      dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    dispatch(loginActions.setLoginError({ loggedIn: false, error }));
  }
};

export const updateReferral = (user, referral) => async (dispatch) => {
  try {
    const { data } = await axios.post("userReferral/" + user.userid, {
      referral: referral,
    });
    if (data.statusCode == 200) {
      dispatch(loginActions.setReferral({ referral: referral }));
      dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    dispatch(loginActions.setLoginError({ loggedIn: false, error }));
  }
};

export const checkoutUpdate =
  (cart, user, orderType, deliveryFee, billingAmount, packagingFee, gstFee) => async (dispatch) => {
    try {
      const { data } = await axios.post("checkout/update/" + user.userid, {
        cart: cart,
        orderType: orderType,
        deliveryFee: deliveryFee,
        billingAmount: billingAmount,
        packagingFee: packagingFee,
        gstFee: gstFee,
      });
      if (data && data.statusCode == 200) {
        dispatch(loginActions.setTotalDishesBooked(cart.length));
        dispatch(cartAction.emptyCartData({ isLoading: false }));
        dispatch(loginActions.setBooking({ booking: data.data }));
        dispatch(loginActions.setOrderDetails(data.data));
        dispatch(bottomBarActions.loadTab({ tabName: "orderSucessfull" }));
      } else if (data && data.statusCode == 400) {
        console.log("error");
        //TO-DO: show error page
      }
    } catch ({ ...error }) {
      console.log(error);
      //TO-DO: show error page
    }
  };

export const updateOrder = (order, deliveryFee) => async (dispatch) => {
  try {
    const { data } = await axios.post("order/update/" + order._id, {
      order: order,
      deliveryFee: deliveryFee,
    });
    if (data && data.statusCode == 200) {
      dispatch(loginActions.setOrderDetails(data.data));
      dispatch(bottomBarActions.loadTab({ tabName: "orderSucessfull" }));
      dispatch(loginActions.setSelectedOrder(null));
    } else if (data && data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const addTempUser = (tempUser) => async (dispatch) => {
  try {
    const { data } = await axios.post("/tempUser", {
      tempUser: tempUser,
    });
    if (data && data.statusCode == 200) {
      console.log("temp_user_created");
    } else if (data && data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

const getDeliveryDetails = () => {
  const now = new Date();

  // Get the current date in the format YYYY-MM-DD
  const delivery_date = now.toISOString().split('T')[0];

  // Get the current hour
  let hour = now.getHours();

  // Calculate the next slot, assuming each slot is 30 minutes
  let nextSlotStartHour = hour;
  let nextSlotEndHour = hour + 1;

  // Format hours to always be two digits
  nextSlotStartHour = nextSlotStartHour < 10 ? `0${nextSlotStartHour}` : nextSlotStartHour;
  nextSlotEndHour = nextSlotEndHour < 10 ? `0${nextSlotEndHour}` : nextSlotEndHour;

  const delivery_slot = `${nextSlotStartHour}:00-${nextSlotEndHour}:00`;

  return {
    delivery_date,
    delivery_slot
  };
}

const createPidgeOrderDelivery = async (data, orderDetails, cart, sender_detail, receiver_detail, PIDGE_API_URL, PIDGE_API_TOKEN, getDeliveryDetails, dispatch, selectedDeliveryPortal) => {
  const isPrepaid = orderDetails.paymentMode === "online" && !!orderDetails.paymentId;
  const deliveryDetailsDate = getDeliveryDetails();

  const apiUrl = `${PIDGE_API_URL}/v1.0/store/channel/vendor/order`;
  const token = PIDGE_API_TOKEN;
  const requestBody = {
    channel: "cuirato",
    sender_detail: sender_detail,
    poc_detail: {
      name: "Ashwin",
      mobile: "9664257767",
      email: "cuirato@yopmail.com"
    },
    trips: [
      {
        receiver_detail: receiver_detail,
        packages: [
          {
            label: "Food package",
            quantity: 1,
            dead_weight: 500
          }
        ],
        source_order_id: data.data.orderRef,
        reference_id: data.data.orderRef,
        cod_amount: isPrepaid ? 0 : orderDetails.billingAmount,
        bill_amount: orderDetails.billingAmount,
        products: cart.map((product) => ({
          name: product.name,
          sku: product._id,
          price: product.price,
          dimension: {
            dead_weight: 100
          },
          image_url: `https://cuirato-images.s3.ap-south-1.amazonaws.com/menu/thumbnail/${product.img}`
        })),
        notes: [
          {
            name: "Food",
            value: "Hot"
          }
        ],
        delivery_date: deliveryDetailsDate.delivery_date,
        delivery_slot: deliveryDetailsDate.delivery_slot
      }
    ]
  };

  const config = {
    headers: {
      Authorization: `${token}`
    }
  };

  try {
    const response = await axios.post(apiUrl, requestBody, config);
    const pidge_order_id = response.data.data[data.data.orderRef];

    if (response && response.data && pidge_order_id) {
      dispatch(updateOrderAction(orderDetails?._id, { pidge_order_id: pidge_order_id, selectedDeliveryPortal }));
    }
  } catch (error) {
  }
};

const createShadowfaxOrderDelivery = async (data, orderDetails, cart, sender_detail, receiver_detail, PIDGE_API_URL, PIDGE_API_TOKEN, getDeliveryDetails, dispatch, pickup_details, drop_details, selectedDeliveryPortal) => {
  const isPrepaid = orderDetails.paymentMode === "online" && !!orderDetails.paymentId;
  try {
    const apiUrl = `${SHADOWFAX_API_URL}/create/`; // Your token
    const token = SHADOWFAX_API_TOKEN
    const requestBody = {
      "pickup_details": {
        ...pickup_details,
        "latitude": orderDetails.chefAddress.lat,
        "longitude": orderDetails.chefAddress.lng
      },
      "drop_details": drop_details,
      "order_details": {
        "order_id": orderDetails.orderRef,
        "is_prepaid": isPrepaid,
        "cash_to_be_collected": isPrepaid ? 0 : orderDetails.billingAmount
      },
      "user_details": {
        "contact_number": "9664257767",
        "credits_key": "4a9f8486-7619-4041-a21a-5677824f6d2a"
      }
    }

    const shadowFaxSampleBody = {
      "pickup_details": {
        "name": "Pramod N P",
        "contact_number": "9999999999",
        "address": "NP Home Foods, Apex Building 93/A Ground Floor, A Wing, 4th B Cross, 5th Block, Koramangala, Bengaluru, Karnataka 560095",
        "landmark": "Above Truffles",
        "latitude": 12.933562657282067,
        "longitude": 77.61429533708163
      },
      "drop_details": {
        "name": "Naksha Rai N D",
        "contact_number": "9999999999",
        "address": "NP Sweet Home, WJM8+FFW, Hosur Rd, KHB Colony, 5th Block, Koramangala, Bengaluru, Karnataka 560095",
        "latitude": 12.93373883438634,
        "longitude": 77.61620254160374
      },
      "order_details": {
        "order_id": orderDetails.orderRef,
        "is_prepaid": true,
        "cash_to_be_collected": 0
      },
      "user_details": {
        "contact_number": "9664257767",
        "credits_key": "4a9f8486-7619-4041-a21a-5677824f6d2a"
      },
      "validations": {
        "pickup": {
          "is_otp_required": true,
          "otp": ""
        },
        "drop": {
          "is_otp_required": true,
          "otp": ""
        },
        "rts": {
          "is_otp_required": true,
          "otp": ""
        }
      },
      "communications": {
        "send_sms_to_pickup_person": false,
        "send_sms_to_drop_person": false,
        "send_rts_sms_to_pickup_person": false
      }
    }

    const config = {
      headers: {
        Authorization: `${token}`
      }
    };

    const response = await axios.post(apiUrl, shadowFaxSampleBody, config);
    if (response && response.data) {
      dispatch(updateOrderAction(orderDetails?._id, { pidge_order_id: response.data.flash_order_id, selectedDeliveryPortal }))
    }

    console.log("Response:", response.data);
    // Handle the response as needed
  } catch (error) {
  }
}

const createPorterOrderDelivery = async (data, orderDetails, cart, sender_detail, receiver_detail, PIDGE_API_URL, PIDGE_API_TOKEN, getDeliveryDetails, dispatch, selectedDeliveryPortal) => {
  const isPrepaid = orderDetails.paymentMode === "online" && !!orderDetails.paymentId;
  const deliveryDetailsDate = getDeliveryDetails();

  const apiUrl = `${API_TEST}porter/orders/create`;
  const porterBody = {
    "request_id": "Cuirato_order_0001",
    "delivery_instructions": {
      "instructions_list": [
        {
          "type": "text",
          "description": "handle with care"
        }
      ]
    },
    "pickup_details": {
      "address": {
        "apartment_address": "",
        "street_address1": sender_detail.address.address_line_1,
        "street_address2": "",
        "landmark": sender_detail.address.landmark,
        "city": sender_detail.address.city,
        "state": sender_detail.address.state,
        "pincode": sender_detail.address.pincode,
        "country": "India",
        "lat": sender_detail.address.latitude,
        "lng": sender_detail.address.longitude,
        "contact_details": {
          "name": sender_detail.name,
          "phone_number": sender_detail.mobile
        }
      }
    },
    "drop_details": {
      "address": {
        "apartment_address": "",
        "street_address1": receiver_detail.address.address_line_1,
        "street_address2": "",
        "landmark": receiver_detail.address.landmark,
        "city": receiver_detail.address.city,
        "state": receiver_detail.address.state,
        "pincode": receiver_detail.address.pincode,
        "country": "India",
        "lat": receiver_detail.address.latitude,
        "lng": receiver_detail.address.longitude,
        "contact_details": {
          "name": receiver_detail.name,
          "phone_number": receiver_detail.mobile
        }
      }
    }
  };

  try {
    const response = await axios.post(apiUrl, porterBody);
    console.log('response', response)
    // const pidge_order_id = response.data.data[data.data.orderRef];
    const porter_order_id = response.data.data.order_id;

    if (response && response.data && porter_order_id) {
      dispatch(updateOrderAction(orderDetails?._id, { pidge_order_id: porter_order_id, selectedDeliveryPortal }));
    }
  } catch (error) {
  }
};

export const checkout =
  (
    cart,
    user,
    email,
    address,
    orderType,
    deliveryFee,
    deliveryDistance,
    deliveryTime,
    totalCost,
    packagingFee,
    gstFee,
    instructionText,
    chefAddress,
    scheduleTime,
    billingAmount,
    paymentMode,
    paymentId,
    pickup_details,
    drop_details,
    sender_detail,
    receiver_detail,
    selectedDeliveryPortal,
    assignDelivery = true,
    discount_type = "",
    discount_total = ""
  ) =>
    async (dispatch) => {
      if(paymentMode === "online" && !paymentId){
        toast.error("Error while verifying the payment status");
        return
      }
      const cartData = applyProportionalDiscount(cart, {discounttype: discount_type, discount: discount_total});
      const discountTypeValue = +discount_type === 1 ? "P" : +discount_type === 2 ? "F" : "";
      try {
        const { data } = await axios.post("checkout/" + user.userid, {
          email: email,
          cart: cartData,
          address: address,
          orderType: orderType,
          deliveryFee: deliveryFee,
          deliveryDistance: deliveryDistance,
          deliveryTime: deliveryTime,
          totalCost: totalCost,
          packagingFee: packagingFee,
          gstFee: gstFee,
          instructionText: instructionText || "",
          chefAddress: chefAddress,
          scheduleTime: scheduleTime || "NOW",
          billingAmount: billingAmount,
          paymentMode: paymentMode,
          paymentId: paymentId,
          discount_type : discountTypeValue,
          discount_total : discount_total,
        });
        if (data && data.statusCode == 200) {
          dispatch(
            loginActions.setBookingDetails({
              booking: data.data,
              email: email,
              dishesBooked: cart.length,
            })
          );
          dispatch(loginActions.setOrderDetails(data.data));

          dispatch(cartAction.emptyCartData({ isLoading: false }));
          // dispatch(chefActions.onBackClick());
          dispatch(bottomBarActions.hideMenu());
          dispatch(cartAction.setIsAddressOptionsVisible(true));
          dispatch(bottomBarActions.loadTab({ tabName: "orderSucessfull" }));
          localStorage.removeItem("checkoutData")
          if (assignDelivery || true) {  //TODO
            const orderDetails = data.data;
            switch (selectedDeliveryPortal) {
              case "PIDGE":
                await createPidgeOrderDelivery(data, orderDetails, cart, sender_detail, receiver_detail, PIDGE_API_URL, PIDGE_API_TOKEN, getDeliveryDetails, dispatch, selectedDeliveryPortal);
                break;
              case "SHADOWFAX":
                await createShadowfaxOrderDelivery(data, orderDetails, cart, sender_detail, receiver_detail, PIDGE_API_URL, PIDGE_API_TOKEN, getDeliveryDetails, dispatch, pickup_details, drop_details, selectedDeliveryPortal)
                break;
              case "PORTER":
                await createPorterOrderDelivery(data, orderDetails, cart, sender_detail, receiver_detail, PIDGE_API_URL, PIDGE_API_TOKEN, getDeliveryDetails, dispatch, selectedDeliveryPortal)
                break;
              default:
                break;
            }
          }
          //TODO: empty local cart
        } else if (data && data.statusCode == 400) {
          console.log("error");
          //TO-DO: show error page
        }
      } catch ({ ...error }) {
        console.log(error);
        //TO-DO: show error page
      }
    };

export const updateOrderAction = (orderId, payload) => async (dispatch) => {
  try {
    const { data } = await axios.post("order/updateStatus/" + orderId, payload);
    if (data && data.statusCode === 200) {
      dispatch(loginActions.setUpdatedBooking(data.data));
      dispatch(loginActions.setOrderDetails(data.data));
      return Promise.resolve(); // Resolve the promise when the update is successful
    } else if (data && data.statusCode === 400) {
      console.log("error");
      // TO-DO: Handle error
      return Promise.reject("Update failed"); // Reject the promise on error
    }
  } catch (error) {
    console.log(error);
    // TO-DO: Handle error
    return Promise.reject("Update failed"); // Reject the promise on error
  }
};


export const updateUser = (user, menuId, type) => async (dispatch) => {
  try {
    let menuIdsOpened = [];
    menuIdsOpened = menuIdsOpened.concat(user.menuIdsOpened);
    menuIdsOpened.push(menuId);
    let menuOpens = user.menuOpens;
    let userUpdates = {
      menuOpens: menuOpens + 1,
      menuIdsOpened: menuIdsOpened,
    };
    const payload = type === "token" ? { firebaseToken: menuId } : userUpdates
    const { data } = await axios.post("user/" + user.userid, payload);
    if (data.statusCode == 200) {
      if (type === "token") {
        dispatch(loginActions.setUserToken({ firebaseToken: data.data.firebaseToken }));
      } else {
        dispatch(loginActions.setMenuOpenDetails(userUpdates));
      }
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const updateExploreDishes = (userid, payload) => async (dispatch) => {
  if (!userid) return
  try {
    const { data } = await axios.post("user/" + userid, payload);
    if (data.statusCode == 200) {
      dispatch(loginActions.setExploredDishesIds(data.data));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const updateDishesCheckedInfo = (userid, payload) => async (dispatch) => {
  if (!userid) return
  try {
    const { data } = await axios.post("user/" + userid, payload);
    if (data.statusCode == 200) {
      dispatch(loginActions.setCheckedDishesInfoIds(data.data));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const updatePausedDishesInfo = (userid, payload) => async (dispatch) => {
  if (!userid) return
  try {
    const { data } = await axios.post("user/" + userid, payload);
    if (data.statusCode == 200) {
      dispatch(loginActions.setPausedDishesIds(data.data));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const updateDishPrice =
  (
    setCurrentPrice,
    calculateTotal,
    dishId,
    isRemovedDish,
    isFromCart,
    maxDishes,
    totalDishesBooked,
    orderPriceIds,
    currentDishPrice,
    cartId
  ) =>
    async (dispatch) => {
      try {

        // const priceData = await axios.get(
        //   "menu/dish/" + Number(cost) + "/" + ingredientCost + "/" + fuelCost + "/" + dishBand + "/" + discountType + "/" + packagingCost + "/" + deliveryCost
        // );
        // if (priceData.status == 200) {
        const currentPrice = currentDishPrice;
        if (setCurrentPrice) setCurrentPrice(currentPrice);
        if (!isFromCart) return;
        if (orderPriceIds?.current.includes(cartId)) return
        orderPriceIds?.current.push(cartId);
        const dealPriceAmount = currentPrice;
        if (calculateTotal) {
          dispatch(
            cartAction.setTotalCost({
              dealPrice: dealPriceAmount,
              dishId: dishId,
              maxDishes: maxDishes,
              totalDishesBooked: totalDishesBooked,
              cartId: cartId,
            })
          );
        } else if (isRemovedDish) {
          dispatch(
            cartAction.removeDishCost({
              dealPrice: dealPriceAmount,
              dishId: dishId,
            })
          );
        }
        return currentPrice;
        //dispatch(dishActions.updatePrice(priceData.data.data));
        // } else if (priceData.status == 400) {
        //   console.log("error");
        //   //TO-DO: show error page
        // }
      } catch (error) {
        console.log(error);
        //TO-DO: show error
      }
    };

export const updateDishCheckedInUser = (user, dishId) => async (dispatch) => {
  try {
    let dishIdsChecked = [];
    dishIdsChecked = dishIdsChecked.concat(user.dishIdsChecked);
    dishIdsChecked.push(dishId);
    let dishPriceChecked = dishIdsChecked.length;
    let userUpdates = {
      dishPriceChecked: dishPriceChecked,
      dishIdsChecked: dishIdsChecked,
    };
    const { data } = await axios.post("user/" + user.userid, userUpdates);
    if (data.statusCode == 200) {
      dispatch(loginActions.setDishPriceCheckedDetails(userUpdates));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};

export const sendOTP =
  ({ mobileNumber, otp }) =>
    async (dispatch) => {
      try {
        const response = await axios({
          baseURL: MSG91_URL,
          url: "otp",
          params: {
            template_id: MSG91_SMS_TEMPLATE_ID,
            authkey: MSG91_AUTH_KEY,
            mobile: mobileNumber,
            otp,
          },
        });
        if (response.statusCode === 200) {
          // TODO: handle success response
          console.log("opt response - ", response);
        }
      } catch ({ ...error }) {
        // TODO: handle error response
        console.log("otp error - ", error);
      }
    };

export const confirmOrder =
  ({ mobileNumber }) =>
    async (dispatch) => {
      try {
        const response = await axios({
          method: "POST",
          baseURL: MSG91_URL,
          url: "flow",
          headers: {
            authkey: MSG91_AUTH_KEY,
            "content-type": "application/JSON",
          },
          data: {
            flow_id: MSG91_SMS_CHECKOUT_TEMPLATE_ID,
            sender: MSG91_SMS_SENDER_ID,
            short_url: "1",
            mobiles: mobileNumber,
          },
        });
        if (response.statusCode === 200) {
          // TODO: handle success response
          console.log(
            "We at Cuirato deeply appreciate that you've pre-ordered your fave dishes. We're geared with platefuls of passion to bring you authentic cuisine prepared by pro chefs real soon!",
            response
          );
        }
      } catch ({ ...error }) {
        // TODO: handle error response
        console.log("Order not placed - ", error);
      }
    };

export const getQueryResponse = (payload) => async (dispatch) => {
  try {
    const { data } = await axios.post("dialogflow", payload);
    if (data.statusCode == 200) {
      // dispatch(loginActions.setQueryResponse(data.data));
      dispatch(loginActions.setQueryResponse({ type: "RECEIVE", text: data.data }));
    } else if (data.statusCode == 400) {
      console.log("error");
      //TO-DO: show error page
    }
  } catch ({ ...error }) {
    console.log(error);
    //TO-DO: show error page
  }
};
