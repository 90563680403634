import React, { useEffect, useRef, useState } from 'react';
import "./chefIndividualScreen.css";
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MenuCategories from '../menu/MenuCategories';
import ImageLoader from '../chef/ImageLoader';
import { fetchChefMenu } from '../../store/menuActions';
import { PREP_TIME } from '../../store/constants';
import { getDisplayChefTimeLabel, getTodayDateValue, secondShiftApplies } from '../../utils/timeHelper';
import moment from 'moment';
import BottomBar from '../BottomBar';
import ChefCardComponent from './ChefCardComponent';
import LeafIconSvg from '../../assets/ReactSvg/LeafIconSvg';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { bottomBarActions } from '../../store/bottomBarSlice';
import { chefActions } from '../../store/chefSlice';
import mixpanel from 'mixpanel-browser';
import { updateUser } from '../../store/loginActions';
import { loginActions } from '../../store/loginSlice';
import { referActions } from '../../store/referSlice';

const ChefIndividualScreen = () => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const dispatch = useDispatch();
  const menuLoaded = useSelector((state) => state.menu.menuLoaded);
  const chefList = useSelector((state) => state.chef.chefList);
  const currentChefIdClicked = useSelector((state) => state.menu.currentChefIdClicked);
  const chefInfoDefaultKey = useSelector((state) => state.menu.chefInfoDefaultKey);
  const [showOpenValidation, setShowOpenValidation] = useState(false);
  const [showDishPriceValidation, setShowDishPriceValidation] = useState(false);
  const [action, setAction] = useState(() => { });
  const [button, setButton] = useState("");
  const [message, setMessage] = useState("");
  const currentChefInfo = chefList.find((c) => c._id === currentChefIdClicked)
  const { cartData, totalCost } = useSelector(({ cart }) => cart);
  const user = useSelector(({ auth }) => auth.user);
  const [categoriesdata, setCategoriesdata] = useState([]);
  const [visible, setVisible] = useState(false)
  const [showAddedItemPopup, setShowAddedItemPopup] = useState(false);
  const menuDetails = useSelector((state) => state.menu.menuDetails);
  const FREE_OPENS = user.maxDishes ? user.maxDishes : 2;
const FREE_OPENS_AFTER_SIGNUP = user.maxChefs ? user.maxChefs : 4;
const FREE_DISH_OPENS = user.maxDishPrices ? user.maxDishPrices : 10;
const freeMenuOpens = useSelector((state) => state.auth.freeMenuOpens);
const askToOpenWithoutSignup = !user.referral && freeMenuOpens < FREE_OPENS;
const askForSignup = !user.referral && freeMenuOpens >= FREE_OPENS;

const askToOpenWithSignup =
  user.referral && user.menuOpens < FREE_OPENS_AFTER_SIGNUP;
const askForRefferal =
  user.referral && user.menuOpens >= FREE_OPENS_AFTER_SIGNUP;

const askForSignupToCheckPrice = !user.referral;
const askForRefferalToCheckPrice = false;
const showFreeDishCheckPriceRemaining =
  user.referral && user.dishPriceChecked < FREE_DISH_OPENS;

  const deliveryDetails = useSelector(
    ({ auth }) => auth.deliveryDetails
  );
  const isServiceable = useSelector((state) => state.auth.isServiceable);

  const getStatusLabel = (chefInfo) => {
    const { isSwitchOff, chefWaitTime, showOnlyDot = false, schedules = [], kitchenId } = chefInfo || {};
    const chefDeliveryDetails = deliveryDetails[kitchenId] || {};
    const delivery_duration = chefDeliveryDetails?.delivery_duration?.value;
    let delivery_Time = delivery_duration ? Math.round(delivery_duration / 60) : 10;

    const chefWaitTimeValue = Math.max(PREP_TIME, chefWaitTime || 0);
    const todayChefSchedule = getTodayDateValue(schedules);

    if (!isServiceable) {
      return "Pre-bookings only"
    } else if (isSwitchOff) {
      return "offline"
    } else if (!todayChefSchedule?.isAvailable) {
      const findNextDayIndex = schedules.findIndex((s) => s._id === todayChefSchedule._id);
      if (findNextDayIndex === (schedules.length - 1)) {
        const nextDayInfo = schedules[0];
        const startTime = nextDayInfo?.startTime || nextDayInfo?.startTime1;
        return `Opens tomorrow at ${startTime}`
      } else {
        const nextDayInfo = schedules[findNextDayIndex + 1];
        const startTime = nextDayInfo?.startTime || nextDayInfo?.startTime1;
        return `Opens tomorrow at ${startTime}`
      }
    } else if (todayChefSchedule?.online) {
      if (delivery_duration) {
        return `Delivery in ${delivery_Time + chefWaitTimeValue} mins`;
      } else {
        return "Open now";
      }
    } else if (todayChefSchedule?.isAvailable) {
      if (secondShiftApplies(todayChefSchedule) && todayChefSchedule?.startTime1 && todayChefSchedule?.endTime1) {
        return `Opens at ${moment(todayChefSchedule?.startTime1, 'h:mm A').format('h a')}`
      }
      return `Opens at ${moment(todayChefSchedule?.startTime, 'h:mm A').format('h a')}`
    }
  }


  const showSignUp = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "account" }));
    dispatch(bottomBarActions.updateFrom({ from: "cart" }));
    mixpanel.track("show-signup-clicked");
  };


  const showReferView = () => {
    dispatch(bottomBarActions.hideMenu());
    dispatch(referActions.onReferClick({ from: "chef" }));
    mixpanel.track("show-refer-clicked");
  };

  useEffect(() => {
    if (menuDetails?.categories) {
      setCategoriesdata(menuDetails?.categories);
    }
  }, [menuDetails?.categories]);

  const openMenu = () => {
    if (user.referral) {
      dispatch(updateUser(user, menuDetails._id, "menu"));
      setShowOpenValidation(false);
    } else {
      dispatch(loginActions.openFreeMenu(menuDetails._id));
      setShowOpenValidation(false);
    }
    mixpanel.track("open-menu-clicked");
    // clevertap.event.push("open-menu-clicked");
  };

  const validateDishPriceCheck = () => {
    if (askForSignupToCheckPrice) {
      setShowDishPriceValidation(false);
      formValidationMessage(false, true);
      return false;
    } else if (askForRefferalToCheckPrice) {
      setShowDishPriceValidation(true);
      formValidationMessage(false, true);
      return false;
    } else {
      setShowDishPriceValidation(false);
      return true;
    }
  };

  const formValidationMessage = (openValidation, priceValidation) => {
    if (openValidation || showOpenValidation) {
      if (askToOpenWithoutSignup) {
        let freeOpens = FREE_OPENS - freeMenuOpens;
        let menu = freeOpens == 1 ? "menu" : "menus";
        let chef = freeOpens == 1 ? " chef" : " chefs";
        setMessage(
          "You can unlock " +
          menu +
          " of " +
          freeOpens +
          chef +
          " before login"
        );
        setAction(() => openMenu);
        setButton("Unlock Menu");
      } else if (askToOpenWithSignup) {
        let freeOpens = FREE_OPENS_AFTER_SIGNUP - user.menuOpens;
        let menu = freeOpens == 1 ? "menu" : "menus";
        let chef = freeOpens == 1 ? "chef" : "chefs";
        setMessage(
          "You can unlock " + menu + " of " + freeOpens + " more " + chef
        );
        setAction(() => openMenu);
        setButton("Unlock Menu");
      } else if (askForSignup) {
        let freeOpens = FREE_OPENS_AFTER_SIGNUP - FREE_OPENS;
        let menu = freeOpens == 1 ? "menu" : "menus";
        let chef = freeOpens == 1 ? "chef" : "chefs";
        setMessage(
          "You can unlock " +
          menu +
          " of " +
          freeOpens +
          " more " +
          chef +
          " on login"
        );
        setAction(() => showSignUp);
        setButton("Login");
      } else if (askForRefferal) {
        let freeOpens = FREE_OPENS_AFTER_SIGNUP;
        let menu = freeOpens == 1 ? "menu" : "menus";
        let chef = freeOpens == 1 ? "chef" : "chefs";
        setMessage(
          "You can unlock " +
          menu +
          " of " +
          FREE_OPENS_AFTER_SIGNUP +
          " more " +
          chef +
          " on Referral"
        );
        setAction(() => showReferView);
        setButton("Refer");
      }
    } else if (priceValidation || showDishPriceValidation) {
      if (askForSignupToCheckPrice) {
        setMessage("Login to check current price of dishes");
        setAction(() => showSignUp);
        setButton("Login");
      } else if (askForRefferalToCheckPrice) {
        setMessage("Refer your friends to check more dish prices");
        setAction(() => showReferView);
        setButton("Refer");
      } else if (showFreeDishCheckPriceRemaining) {
        let freeOpens = FREE_DISH_OPENS - user.dishPriceChecked;
        let dish = (freeOpens = 1 ? "dish" : "dishes");
        setMessage(
          "Check current price of up to " +
          (FREE_DISH_OPENS - user.dishPriceChecked) +
          " " +
          dish
        );
        setAction(() => validateDishPriceCheck);
        setButton("Okay");
      }
    }
  };

  useEffect(() => {
    if (totalCost !== 0) {
      setShowAddedItemPopup(true);
      setShowOpenValidation(false);
      setShowDishPriceValidation(false);
    } else {
      setShowAddedItemPopup(false);
    }
  }, [totalCost]);

  const showCart = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "cart" }));
    dispatch(chefActions.onRedirect());
    mixpanel.track("menu-view-cart-clicked");
  };

  useEffect(() => {
    if (!visible) return;
    if (menuDetails?.chefId !== currentChefInfo?._id) {
      dispatch(fetchChefMenu(currentChefInfo?._id));
    }
  }, [visible]);

  const totalPortions = cartData.reduce((acc, cartItem) => {
    return acc + cartItem.portion;
  }, 0);

  const todayChefSchedule = getTodayDateValue(currentChefInfo?.schedules);

  const textOutput = getDisplayChefTimeLabel(todayChefSchedule);

  const onCheckPrice = async (dishID, setShowAdd) => {
    // if (validateDishPriceCheck()) {
    //   dispatch(updateDishCheckedInUser(user, dishID));
    //   setShowAdd(true);
    // }
    //call pricing api to get current price
  };

  const onChange = (key) => {
    console.log(key);
  };

  const tabsItems = [
    {
      key: 1,
      label: 'MENU',
      forceRender: true,
      children: <div className='menuCategoriesWrapper'>
        {menuLoaded ? (
          <MenuCategories
            categories={categoriesdata}
            saff={false}
            onCheckPrice={onCheckPrice}
            setShowAddedItemPopup={setShowAddedItemPopup}
            parentComponent="chef"
          />
        ) : <ImageLoader className="loader_wrapper" />}
      </div>,
    },
    {
      key: 2,
      label: 'ABOUT CHEF',
      forceRender: true,
      children: <div>
         <div className='aboutChefInfoWrapperDescription'>
          <span><LeafIconSvg /> {`${currentChefInfo?.label1}`}</span>
          <span><LeafIconSvg /> {`${currentChefInfo?.label2}`}</span>
          <span><LeafIconSvg /> {`${currentChefInfo?.label3}`}</span>
        </div>
      </div>,
    }
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 10);

    // Clear the timeout if the component unmounts before 1 second
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='chef-individual-screen-wrapper'>
      <div className={`chef-individual-screen ${visible ? 'open' : ''}`}>
        <div className='chefImageWrapper' >
          <ChefCardComponent chef={currentChefInfo} isChefMainScreen={true} />
          <Tabs className='chefInfoTabs' defaultActiveKey={chefInfoDefaultKey} items={tabsItems} onChange={onChange} />
        </div>
      </div>
      {!isKeyboardOpen && (
        <div
          className={
            showOpenValidation || showDishPriceValidation || showAddedItemPopup
              ? "browser-snackbar active"
              : "browser-snackbar deactive"
          }
          id="cart_option"
        >
          <div
            onClick={
              !showOpenValidation &&
                !showDishPriceValidation &&
                showAddedItemPopup
                ? showCart
                : action
            }
            className="browser-snackbar-detail"
          >
            <ul className="footer-list">
              <li className="list-item-options">
                <a href="#" className="menu-link">
                  {!showOpenValidation &&
                    !showDishPriceValidation &&
                    showAddedItemPopup && (
                      <div className="total-price-container">
                        <span style={{ margin: 0 }}>
                          {totalPortions} {totalPortions == 1 ? "Item" : "Items"} Added
                        </span>

                        <span onClick={showCart}>
                          View Cart
                          <img
                            src="images/icon-view-cart.svg"
                            alt="view-cart"
                            loading="lazy"
                          />
                        </span>
                      </div>
                    )}
                  {(showOpenValidation || showDishPriceValidation) && (
                    <span>{button}</span>
                  )}
                </a>
              </li>
              {(showOpenValidation || showDishPriceValidation) && (
                <li className="list-item-description">
                  <div className="total-items">
                    <a href="#" className="cart-items">
                      <span>{message}</span>
                    </a>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      <BottomBar isExplore />
    </div>
  )
}

export default ChefIndividualScreen