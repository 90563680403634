import { createSlice } from "@reduxjs/toolkit";
import { countDiscountedAmount, getUniqueArray, mergeCartData } from "../utils/helper";

const setToLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

const getFromLocalStorage = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

const initialState = {
  cartData: [],
  menuCartData: [],
  error: false,
  isLoading: false,
  totalCost: 0,
  chefWaitingTime: getFromLocalStorage("chefWaitingTime") || [],
  isAddressOptionsVisible: getFromLocalStorage("isAddressOptionsVisible") === false ? false : true,
  instructionText: "",
  scheduleTime: null,
  selectedDiscount: {},
  isScheduleNow : true
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart(state, action) {
      state.isLoading = action.payload.isLoading;
      if (action.payload?.isCartApi) {
        const updatedCartData = mergeCartData(
          state.cartData,
          action.payload.cartData
        );
        state.cartData = updatedCartData;
      } else {
        state.cartData = action.payload.cartData;
      }
      state.cartData = state.cartData?.filter((item) => item?.portion !== 0 && item?.portion && item?.dealPrice !== 0); //to filter and to maitain portion property
      return state;
    },
    setMenuCart(state, action) {
      state.isLoading = action.payload.isLoading;
      state.menuCartData = action.payload.cartData;
      if (state.menuCartData.length > 0) {
        let totalCost = 0;
        state.menuCartData.forEach((cart) => {
          totalCost += cart.dealPrice;
        });
        state.totalCost = totalCost;
      }
      const totalCost = countDiscountedAmount(state.totalCost ,state.selectedDiscount);
      state.totalCost = totalCost;
      return state;
    },
    addCartData(state, action) {
      let cartDataFromPayload = {
        ...action.payload.cartData,
        isAddedOnDiscount: action.payload.cartPayload.isAddedOnDiscount,
      };
      const cartDataArray = [...state.cartData];
      const index = cartDataArray.findIndex((i) => i.cartId === cartDataFromPayload.cartId);
      if(index !== -1){
        cartDataArray[index].portion = cartDataFromPayload.portion;
      }
      const cartUniqData = getUniqueArray([...cartDataArray, cartDataFromPayload ]);
      state.cartData = cartUniqData;
      state.cartData = state.cartData?.filter((item) => item?.portion !== 0 && item?.portion && item?.dealPrice !== 0);
      const updatedMenuCartData = state.menuCartData.map((item) => {
        const index = item?._id ? item?._id : item?.cartId
        if (index === cartDataFromPayload.cartId) {
          return {
            ...item,
            portion: action.payload.cartPayload.portion,
          };
        }
        return item;
      });
      if (!state.menuCartData.some(item => (item?._id ? item._id : item.cartId  === cartDataFromPayload.cartId))) {
        updatedMenuCartData.push(action.payload.cartPayload);
      }
      state.menuCartData = updatedMenuCartData;
      state.isLoading = action.payload.isLoading;
      if (action.payload?.isFromCart) {
        if(action.payload?.actionType === "ADD"){
          state.totalCost += action.payload.cartPayload.dealPrice;
        }else{
          state.totalCost -= action.payload.cartPayload.dealPrice;
        }
      }
      
      const totalCost = countDiscountedAmount(state.totalCost ,state.selectedDiscount);
      state.totalCost = totalCost;
      
      return state;
    },
    removeCardData(state, action) {
      let dealPrice = 0;
      let price = 0;
      let isAddedOnDiscount;
      state.isLoading = action.payload.isLoading;
      const cartIndex = state.cartData.findIndex(
        (cart) => cart.cartId === action.payload.cartId
      );
      const countTotalOrder = state.cartData.filter(
        (cart) => cart.cartId === action.payload.cartId
      ).length;
      state.cartData.forEach((cart, index) => {
        if (cart.cartId === action.payload.cartId) {
          if (cartIndex === index && countTotalOrder === 1) {
            state.cartData.splice(index, 1);
          }
          price = cart.price;
          isAddedOnDiscount = cart.isAddedOnDiscount;
        }
      });
      state.cartData = state.cartData?.filter((item) => item?.portion !== 0 && item?.portion && item?.dealPrice !== 0); //to filter and to maitain portion property
      const menuCartIndex = state.menuCartData.findIndex(
        (cart) => cart.dishId === action.payload.dishId
      );
      if (menuCartIndex !== -1) {
        const cart = state.menuCartData[menuCartIndex];
        state.menuCartData.splice(menuCartIndex, 1);
        dealPrice = cart.dealPrice;
      }
      if (action.payload?.isFromCart) {
        state.totalCost -= action.payload.dealPrice;
      }
      const totalCost = countDiscountedAmount(state.totalCost ,state.selectedDiscount);
      state.totalCost = totalCost;
      
      return state;
    },
    emptyCartData(state, action) {
      state.isLoading = action.payload.isLoading;
      state.cartData = [];
      state.menuCartData = [];
      state.totalCost = 0;
      state.instructionText = "";
      
      return state;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload.isLoading;
      state.cartData = [];
      
      return state;
    },
    setTotalCost(state, action) {
      state.cartData.forEach((cart) => {
        if (cart.cartId == action.payload.cartId) {
          cart.dealPrice = action.payload.dealPrice;
          if (!isNaN(cart.dealPrice) && !isNaN(cart.portion)) {
            state.totalCost += cart.dealPrice * cart.portion;
          }
        }
      });
      state.cartData = state.cartData?.filter((item) => item?.portion !== 0 && item?.portion && item?.dealPrice !== 0);
      const totalCost = countDiscountedAmount(state.totalCost ,state.selectedDiscount);
      state.totalCost = totalCost;
      
      return state;
    },
    calculateTotalCost(state) {
      let totalCost;
      state.cartData.forEach((cart) => {
        totalCost = state.totalCost + cart.dealPrice;
      });
      state.cartData = state.cartData?.filter((item) => item?.portion !== 0 && item?.portion && item?.dealPrice !== 0);
      state.totalCost = totalCost;
      const totalCostValue = countDiscountedAmount(state.totalCost ,state.selectedDiscount);
      state.totalCost = totalCostValue;
      
      return state;
    },
    removeDishCost(state, action) {
      const totalCostValue =  state.totalCost - action.payload.dealPrice;
      const totalCost = countDiscountedAmount(totalCostValue ,state.selectedDiscount);
      state.totalCost = totalCost;
      return state;
    },
    resetTotalCost(state, action) {
      state.totalCost = 0;
      return state;
    },
    setChefWaitingTime(state, action) {
      state.chefWaitingTime = action.payload.time || [];
      setToLocalStorage("chefWaitingTime", action.payload.time || []);
      return state;
    },
    setIsAddressOptionsVisible(state, { payload }) {
      state.isAddressOptionsVisible = payload;
      setToLocalStorage("isAddressOptionsVisible", payload);
    },
    setInstructionText(state, { payload }) {
      state.instructionText = payload;
    },
    setScheduleTime(state, { payload }) {
      state.scheduleTime = payload;
    },
    setSelectedDiscount(state, { payload }) {
      state.selectedDiscount = payload;
      const totalCost = countDiscountedAmount(state.totalCost ,payload);
      state.totalCost = totalCost;
    },
    setIsScheduleNow(state, { payload }) {
      state.isScheduleNow = payload;
    },
  },
});

export const cartAction = cartSlice.actions;

export default cartSlice;
