import { getToken } from "@firebase/messaging";
import moment from "moment";


export const getUniqueArray = (array) => {
  const uniqueIds = [];
  return array.filter((obj) => {
    if (!uniqueIds.includes(obj.cartId)) {
      uniqueIds.push(obj.cartId);
      return true;
    }
    return false;
  });
};

export const isWebApp = () => {
  const isStandalone =
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true;
  const isHTTPS = window.location.protocol === "https:";
  return !isStandalone && isHTTPS;
};

export const getExactTime = (timestamp, moreMinutes, isOrderScheduled) => {
  const targetTime = new Date(timestamp);
  if (moreMinutes) {
    targetTime.setMinutes(targetTime.getMinutes() + moreMinutes);
  }
  const adjustedTimestamp = new Date(targetTime);
  if (isOrderScheduled) {
    adjustedTimestamp.setHours(targetTime.getHours() - 5);
    adjustedTimestamp.setMinutes(targetTime.getMinutes() - 30);
  }
  const momentTimeFormate = isOrderScheduled ? moment(adjustedTimestamp).format("MMM D, h:mm A") : moment(targetTime).format("h:mm A");
  return `${momentTimeFormate}`;
};

export const getOrderStatusLabel = (orderStatus) => {
  switch (orderStatus) {
      case "CREATED":
        return "Order Placed";
      case "CONFIRMED":
        return "Order Confirmed";
      case "PICKED_UP":
      case "OUT_FOR_DELIVERY":
      case "live":
        return "Order on the way";
      case "UNDELIVERED":
        return "Can not deliver";
      case "RTO_OUT_FOR_DELIVERY":
        return "Kitchen Return - En Route";
      case "RTO_DELIVERED":
        return "Delivered to kitchen";
      case "REACHED_DELIVERY":
        return "Rider arrived";
      case "READY":
        return "Order is ready.";
      case "DELIVERED":
      case "completed":
      case "ended":
        return "Your order is delivered.";
      case "CANCELLED":
        return "Your order is cancelled.";
      case "ALLOTTED":
        return "Your order's rider is allotted.";
      case "ACCEPTED":
        return "Rider accepted the order.";
      case "ARRIVED":
        return "Rider arrived to pick up.";
      case "COLLECTED":
        return "Rider collected the order.";
      case "ARRIVED_AT_CUSTOMER_DOORSTEP":
        return "Rider arrived at the doorstep.";
      case "RTS_INITIATED":
        return "Your order returning to kitchen.";
      case "RTS_COMPLETED":
        return "Your order returned to kitchen.";
      case "cancelled":
        return "Rider cancelled order.";
      default:
        return "Your order is in progress.";
  }
};

export const combineTheDate = (date1, date2) => {
  const year = date1?.year();
  const month = date1?.month();
  const day = date1?.date();

  // Extract time components from date2
  const hours = date2?.getHours();
  const minutes = date2?.getMinutes();
  const seconds = date2?.getSeconds();

  // Create a new Date object with combined date and time
  const newDate = new Date(year, month, day, hours, minutes, seconds);
  return newDate;
};


export const isIOSDevice = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const capitalizeFirstLetter = (string = "") => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

export const mergeCartData = (stateCartData, payloadCartData) => {
  return payloadCartData.map((data) => {
    const existingIndex = stateCartData.findIndex(
      (stateItem) => stateItem._id === data._id
    );
    if (existingIndex !== -1) {
      if (stateCartData[existingIndex]?.dealPrice) {
        data.dealPrice = stateCartData[existingIndex]?.dealPrice;
      }
    }
    return data;
  });
};

const calculateDiscountedPrice = (originalPrice, percentageOff) => {
  const discount = originalPrice * (percentageOff / 100);
  const discountedPrice = originalPrice - discount;
  return discountedPrice;
}

const getDishPriceBasedOnTime = (prepTime, originalPrice, scheduleOrder, discountType, dishSpecialType = "") => {
  if (dishSpecialType === "daily") {
    const fullDiscount = discountType === "deepDiscount" ? 90 : 80;
    const midDiscount = discountType === "deepDiscount" ? 80 : 70;
    const lessDiscount = discountType === "deepDiscount" ? 70 : 60;
    if (prepTime <= 31 || scheduleOrder) {
      return calculateDiscountedPrice(originalPrice, 100)
    } else if (prepTime < 45) {
      return calculateDiscountedPrice(originalPrice, 100)
    } else {
      return calculateDiscountedPrice(originalPrice, 100)
    }
  } else {
    const fullDiscount = discountType === "deepDiscount" ? 75 : 50;
    const midDiscount = discountType === "deepDiscount" ? 67.5 : 25;
    const lessDiscount = discountType === "deepDiscount" ? 50 : 0;
    if (prepTime <= 31 || scheduleOrder) {
      return calculateDiscountedPrice(originalPrice, fullDiscount)
    } else if (prepTime < 45) {
      return calculateDiscountedPrice(originalPrice, midDiscount)
    } else {
      return calculateDiscountedPrice(originalPrice, lessDiscount)
    }
  }
}

export const calculateCurrentPrice = (dish, currentChefsTime, currentChefDetail, scheduleOrder = false, discountType, dishSpecialType = "") => {
  const dishPrepTime = Math.max(currentChefsTime?.chefWaitTime, currentChefDetail?.prepTime);
  const dishMargin = dish.margin;
  const dishPrice = dish.price;
  const dishBaseCost = dishPrice - dishMargin;
  const currentPrice = getDishPriceBasedOnTime(dishPrepTime, dishMargin, scheduleOrder, discountType, dishSpecialType) + dishBaseCost;
  return currentPrice;
};

export const flatten2DArray = (array) => {
  return array.reduce((acc, row, rowIndex) => {
    row.dishes?.forEach((value, colIndex) => {
      const index = colIndex * array.length + rowIndex;
      acc[index] = { ...value, categoryHeader: row?.name, dishSpecialType: row?.type, categoryImg: row?.img, categoryId: row?._id };
    });
    return acc;
  }, []);
}

export const getNextStoryImages = (array) => {
  const result = array.map((item, index) => {
    const storyImages = item.stories.map((story) => story.img);
    const nextItem = array[index + 1];

    // Check if nextItem is defined and has stories array
    const nextTwoStoryImages = nextItem && nextItem.stories
      ? nextItem.stories.slice(0, 1).map((story) => story.img || "")
      : [];

    return {
      ...item,
      storyImages: [...storyImages, ...nextTwoStoryImages],
    };
  });
  return result
}

export const sortedDishArray = (dishArray) => {
  return dishArray.sort((a, b) => {
    if (a.dishSpecialType < b.dishSpecialType) {
      return -1;
    } else if (a.dishSpecialType > b.dishSpecialType) {
      return 1;
    } else {
      return 0;
    }
  });
}

export const exploreDemoStory = {
  "unavailable": false,
  "isActive": true,
  "isDeleted": false,
  "_id": "6476e5ee90804901f51212e3",
  "name": "",
  "img": "",
  "type": "",
  "categoryType": "DEMO",
  "description": "",
  "ingredientCost": null,
  "packagingCost": null,
  "fuelCost": null,
  "deliveryCost": null,
  "margin": null,
  "price": null,
  "chefId": "6459297a5214569aa0c150a9",
  "chefName": "Pratap",
  "chefImg": "https://cuirato-images.s3.ap-south-1.amazonaws.com/display-pic/Pratap-Singh-Pic.jpg",
  "stories": [
    {
      "isActive": true,
      "isDeleted": false,
      "_id": "6476e5ee10304901f503ccf1",
      "img": "tawa sabzi_story2.jpg",
      "demoLabel": "Meet the top 1% chefs in your city",
      "demoDesc": "Professional Chefs turned entrepreneurs now at your doorstep",
      "storyType": "DEMO",
      "createdAt": "2023-05-31T06:15:11.267Z",
      "updatedAt": "2023-05-31T06:15:11.267Z"
    },
    {
      "isActive": true,
      "isDeleted": false,
      "_id": "6476e5ee10304901f503ccf2",
      "img": "paneer lababdar_story3.jpg",
      "demoLabel": "Order now from Professional Chefs",
      "demoDesc": "Professional Chefs turned entrepreneurs now at your doorstep",
      "storyType": "DEMO",
      "createdAt": "2023-05-31T06:15:11.267Z",
      "updatedAt": "2023-05-31T06:15:11.267Z"
    },
    {
      "isActive": true,
      "isDeleted": false,
      "_id": "6476e5ee10304901f503ccf3",
      "img": "chicken malai tikka_story0.jpg",
      "header": "INGREDIANT",
      "caption": "deep fried golden brown garlic mixed with steam rice and vegetables. ",
      "storyHeader": "Daily specials",
      "dishNameHeader": "tawa sabzi",
      "createdAt": "2023-05-31T06:15:11.267Z",
      "updatedAt": "2023-05-31T06:15:11.267Z"
    },
    {
      "isActive": true,
      "isDeleted": false,
      "_id": "6476e5ee10304901f503ccf4",
      "img": "chicken malai tikka_story1.jpg",
      "header": "FLAVOUR",
      "caption": "deep fried golden brown garlic mixed with steam rice and vegetables.",
      "storyHeader": "Weekly specials",
      "dishNameHeader": "paneer lababdar",
      "createdAt": "2023-05-31T06:15:11.267Z",
      "updatedAt": "2023-05-31T06:15:11.267Z"
    }
  ],
  "createdAt": "2023-05-31T06:15:11.267Z",
  "updatedAt": "2023-05-31T06:15:11.267Z",
  "categoryHeader": "",
  "dishSpecialType": "DEMO",
  "categoryImg": "Paneer lababdar.jpg",
  "categoryId": "1234e666df3bdfea46626e09",
  "storyImages": [
    "Veg kolhapuri.jpg",
    "veg burnt garlic rice.jpg"
  ]
}

export const extractPincode =(address) => {
  // Regular expression to match a 6-digit pincode
  const pincodePattern = /\b\d{6}\b/;
  const match = address.match(pincodePattern);
  return match ? match[0] : null;
}

export const countDiscountedAmount = (totalAmountCost = 0, selectedDiscount) => {
  
  const { discounttype, discount: discountValue } = selectedDiscount || {};
  const discount = +discountValue;
  if (!totalAmountCost || isNaN(totalAmountCost)) {
    console.log("Invalid total amount cost")
    return 0
  }
  
  if (!discount || isNaN(discount)) {
    return totalAmountCost; 
  }
  
  let discountedAmount = totalAmountCost;

  if (+discounttype === 1) {
    discountedAmount = totalAmountCost - (totalAmountCost * discount) / 100;
  } else if (+discounttype === 2) {
    discountedAmount = totalAmountCost - discount;
  }

  return Math.max(discountedAmount, 0);
};

const countPortionDiscountedAmount = (totalAmountCost, selectedDiscount) => {
  const { discounttype, discount: discountValue } = selectedDiscount || {};
  const discount = +discountValue;
  if (!totalAmountCost || isNaN(totalAmountCost)) {
    console.log("Invalid total amount cost")
    return 0
  }

  if (!discount || isNaN(discount)) {
      return totalAmountCost;
  }

  let discountedAmount = totalAmountCost;

  if (+discounttype === 1) {
      // Percentage discount
      discountedAmount = totalAmountCost - (totalAmountCost * discount) / 100;
  } else if (+discounttype === 2) {
      // Flat discount
      discountedAmount = totalAmountCost - discount;
  }

  return Math.max(discountedAmount, 0);
};

export const applyProportionalDiscount = (cart, selectedDiscount) => {
  const totalDealPrice = cart.reduce((sum, item) => sum + item.dealPrice, 0);

  const totalDiscountedPrice = countPortionDiscountedAmount(totalDealPrice, selectedDiscount);

  const totalDiscount = totalDealPrice - totalDiscountedPrice;

  let remainingDiscount = totalDiscount;

  const updatedCart =  cart.map((item, index) => {
    let discountedAmount;

    if (index === cart.length - 1) {
      discountedAmount = parseFloat(remainingDiscount.toFixed(2));
    } else {
      discountedAmount = parseFloat((totalDiscount * (item.dealPrice / totalDealPrice)).toFixed(2));
      remainingDiscount -= discountedAmount;
    }

    return {
      ...item,
      discountedAmount,
      dealPrice: parseFloat((item.dealPrice - discountedAmount).toFixed(2)),
    };
  });
  return updatedCart
};

export const DEMO_SHADOWFAX_SERVICIBILLITY_CHECK_PAYLOAD = {
  "pickup_details": {
    "building_name": "NP Home Foods",
    "latitude": 12.933562657282067,
    "longitude": 77.61429533708163,
    "address": "Apex Building 93/A Ground Floor, A Wing, 4th B Cross, 5th Block, Koramangala, Bengaluru, Karnataka 560095"
  },
  "drop_details": {
    "building_name": "NP Home",
    "latitude": 12.93373883438634,
    "longitude": 77.61620254160374,
    "address": "WJM8+FFW, Hosur Rd, KHB Colony, 5th Block, Koramangala, Bengaluru, Karnataka 560095"
  }
}
