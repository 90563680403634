import React, { useEffect, Suspense, useState, useLayoutEffect, useRef } from "react";
import BottomBar from "../BottomBar";
import Dish from "../menu/Dish";
import cartBg from "../../assets/cartBg.png";
import browseLogo from "../../assets/browseLogo.png";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { setCartData } from "../../store/cartActions";
import { cartAction } from "../../store/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import "./cart.css";
import {
  API_TEST,
  DELIVERY_CHARGE_PER_KMS,
  EVENING_BREAK_SHIFT_END_TIME,
  EVENING_BREAK_SHIFT_START_TIME,
  EVENING_SHIFT_END_TIME,
  EVENING_SHIFT_START_TIME,
  FREE_DILIVERY_DISTANCE_RANGE,
  MORNING_BREAK_SHIFT_END_TIME,
  MORNING_BREAK_SHIFT_START_TIME,
  MORNING_SHIFT_END_TIME,
  MORNING_SHIFT_START_TIME,
  PREP_TIME,
  S3_MENU_FOLDER,
  S3_MENU_THUMBNAIL_FOLDER,
  WEEK_OFF,
} from "../../store/constants";
import { SuspenseImg } from "../util/SuspendImg";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ImageLoader from "../chef/ImageLoader";
import "../menu/browse-menu-button.css";
import { red } from "@material-ui/core/colors";
import ReactGA from "react-ga4";
import { checkout, checkoutUpdate } from "../../store/loginActions";
import { referActions } from "../../store/referSlice";
import mixpanel from "mixpanel-browser";
import { ToastContainer } from "react-toastify";
import { loginActions } from "../../store/loginSlice";
import EarlyUsers from "../userprofile/EarlyUsers";
import { BenefitsSkims, EarlyUsersBoxWrapper, PopBenifits } from "../userprofile/styled";
import ReactPixel from 'react-facebook-pixel';
import { combineTheDate, countDiscountedAmount } from "../../utils/helper";
import ScheduleOrderButton from "../ScheduleOrderButton/Index";
import axios from "axios";
import moment from "moment";
// import clevertap from "clevertap-web-sdk";
import { getTodayDateValue } from "../../utils/timeHelper";
import { useSearchParams } from "react-router-dom";
import { uniq, uniqBy } from "lodash";
import { Button } from "antd";

const GST_RATE = 5;
const MAX_ORDER_DISHES = 2;

const Cart = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromScreen = urlParams.get('fromScreen');
  const isCheckoutCancel = fromScreen === "checkoutCancel"
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "cart",
    });
  }, []);
  const user = useSelector(({ auth }) => auth.user);
  const {
    cartData,
    instructionText: instructionTextReducer,
    totalCost,
    isAddressOptionsVisible,
    scheduleTime,
    isScheduleNow,
  } = useSelector(({ cart }) => cart);
  const [chefWaitingTime, setChefWaitingTime] = useState([]);
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);
  const [prevDeliveryInfoText, setPrevDeliveryInfoText] = useState("");
  const chefList = useSelector((state) => state.chef.chefList);
  const isKitchenFull = useSelector((state) => state.chef.isKitchenFull);
  const selectedAddress = useSelector((state) => state.auth.selectedAddress);
  const addresses = user.addressLine1;
  const dispatch = useDispatch();
  const orderPriceIds = useRef([]);
  let maxDishesAllowed = user.maxDishes - user.totalDishesBooked;
  const reducer = (previousValue, currentValue) => previousValue + currentValue;
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);
  const deliveryOptions = useSelector((state) => state.auth.availableDeliveryOptions);
  const unserviceableModalOnCart = useSelector((state) => state.auth.unserviceableModalOnCart);
  const farAwayModalOnCart = useSelector((state) => state.auth.farAwayModalOnCart);
  const minimumAmount = useSelector((state) => state.auth.minimumAmount);
  const isMenuClicked = useSelector((state) => state.menu.isMenuClicked);
  // const delivery_distance = useSelector(
  //   ({ auth }) => auth.deliveryDetails?.delivery_distance
  // );
  let user_location = useSelector((state) => state.auth.location);
  const categories = useSelector((state) => state.chef.categories);
  const cartChefIds = cartData.map((a) => a.chefId);
  const mergeDishes = (chefIds, chefData) => {
    const mergedDishes = chefIds.reduce((acc, chefId) => {
      if (chefData[chefId]) {
        const allMergedArray = chefData[chefId].map((c) => c.dishes.map((dish) => ({ ...dish, dishSpecialType: c?.type })));
        acc.push(...allMergedArray.flat());
      }
      return acc;
    }, []);
    return mergedDishes;
  }
  const mergedDishesArray = mergeDishes(cartChefIds, categories);
  const cartDishesIds = cartData.map((c) => c._id);
  const cartDishes = mergedDishesArray.filter((c) => cartDishesIds.includes(c._id))
  const hasDailySpecial = uniqBy(cartDishes, obj => obj._id)?.every(dish => dish.dishSpecialType === "daily");

  const cartChefAddedKitchenId = chefList.find((a) => cartChefIds.includes(a._id))?.kitchenId
  const deliveryDetails = useSelector(
    ({ auth }) => auth.deliveryDetails
  );
  const chefDeliveryDetails = deliveryDetails[cartChefAddedKitchenId] || {};
  const delivery_duration = chefDeliveryDetails?.delivery_duration?.value;
  const delivery_distance = chefDeliveryDetails?.delivery_distance;
  const allCartChefList = chefList?.filter((a) => cartChefIds.includes(a._id))?.map((c) => c.kitchenId) || [];
  const kitchensList = useSelector((state) => state.chef.kitchensList);
  const currentSelectedKitchen = kitchensList.find((kitchen) => kitchen._id === allCartChefList[0]) || {};
  const kitchenDiscount = currentSelectedKitchen?.discounts || [];
  // const delivery_duration = useSelector(
  //   ({ auth }) => auth.deliveryDetails?.delivery_duration?.value
  // );

  const allChefsWaitingTime = chefWaitingTime?.map((chef) => {
    const currentChef = cartData.filter((a) => a.chefId === chef.chefId).map((a) => a.portion).reduce((partialSum, a) => partialSum + a, 0) * 5;
    return 0 < currentChef ? chef.chefWaitTime + currentChef : 0;
  })

  const preparationTime = Math.max(PREP_TIME, Math.max(...allChefsWaitingTime));
  let delivery_Time =
    preparationTime + (delivery_duration ? Math.round(delivery_duration / 60) : 10);

  const scheduleDateTime = combineTheDate(
    scheduleTime?.date,
    scheduleTime?.time
  );
  const [unAvailable, setUnAvailable] = useState(false);
  const [isSwitchedOffChef, setIsSwitchedOffChef] = useState(false);

  const [unAvailableChef, setUnAvailableChef] = useState([]);
  const [selectedDiscount, setSelectedDiscount] = useState({});
  const [chefsLatestTime, setChefsLatestTime] = useState("");
  const [instructionText, setInstructionText] = useState(
    instructionTextReducer
  );
  const kitchenFull = isKitchenFull || false;

  useEffect(() => {
    dispatch(setCartData(user.userid, user.maxDishes));
  }, [dispatch, user.userid]);

  useEffect(() => {
    const chefIds = cartData.map((c) => c.chefId);
    const cartChefList = chefList.filter((chef) => chefIds.includes(chef._id));
    const allChefsStartTime = cartChefList.map((c) => (getTodayDateValue(c.schedules).startTime || getTodayDateValue(c.schedules).startTime1))
    const timeObjects = allChefsStartTime.map(timeStr => new Date(`2000-01-01 ${timeStr}`));
    const latestTime = new Date(Math.max(...timeObjects.map(time => time.getTime())));
    const latestTimeStr = latestTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    const momentObject = moment(latestTimeStr, "h:mm A");
    setChefsLatestTime(momentObject)
    const chefStatusList = cartChefList.map((chef) => ({ chefId: chef._id, online: chef.online, status: chefStatus(chef), isSwitchOff: !!chef?.isSwitchOff }))
    const offlineChefs = chefStatusList.filter((c) => !c.online).map((c) => c.chefId);
    const switchedOffChefs = chefStatusList.filter((c) => c.isSwitchOff).map((c) => c.chefId);
    setUnAvailableChef([...new Set([...offlineChefs, ...switchedOffChefs])])
    setUnAvailable(!!offlineChefs.length)
    setIsSwitchedOffChef(!!switchedOffChefs.length)
  }, [cartData]);

  async function fetchChefTimeApiCall(chefId) {
    try {
      const response = await axios.get(
        `${API_TEST}getDeliveryDelayTimeByChef/${chefId}`
      );
      const responseData = response.data;
      return { chefWaitTime: responseData.data.chefWaitTime, chefId };
    } catch (error) {
      console.error("Error making API call:", error.message);
      throw error;
    }
  }

  const fetchChefTimeData = async () => {
    const uniqueChefIds = new Set();
    const uniqueChefArray = cartData.filter((item) => {
      if (!uniqueChefIds.has(item.chefId)) {
        uniqueChefIds.add(item.chefId);
        return true;
      }
      return false;
    }).map((chef) => chef.chefId);
    try {
      const apiChefCalls = uniqueChefArray.map((chefId) => fetchChefTimeApiCall(chefId));
      const results = await Promise.all(apiChefCalls);
      dispatch(cartAction.setChefWaitingTime({ time: results }));
      setChefWaitingTime(results)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchChefTimeData();
  }, []);

  useLayoutEffect(() => {
    dispatch(cartAction.resetTotalCost());
  }, [dispatch]);

  const chefStatus = (chef) => {
    const today = isScheduleNow ? new Date().getDay() : new Date(scheduleDateTime).getDay();
    const now = isScheduleNow ? new Date().getHours() : new Date(scheduleDateTime).getHours();
    let isAvailable = "away";
    if (chef.leave) {
      isAvailable = "leave"
    } else if (chef.breakShift) {
      const now = isScheduleNow ? new Date().getHours() : new Date(scheduleDateTime).getHours();
      if (now >= MORNING_BREAK_SHIFT_START_TIME && now < MORNING_BREAK_SHIFT_END_TIME) {
        isAvailable = "online"
      } else if (now >= EVENING_BREAK_SHIFT_START_TIME && now < EVENING_BREAK_SHIFT_END_TIME) {
        isAvailable = "online"
      }
    } else {
      const now = isScheduleNow ? new Date().getHours() : new Date(scheduleDateTime).getHours();
      if (now >= MORNING_SHIFT_START_TIME && now < MORNING_SHIFT_END_TIME) {
        if (chef.shift) {
          isAvailable = "online"
        }
      } else if (now >= EVENING_SHIFT_START_TIME || now < EVENING_SHIFT_END_TIME) {
        if (!chef.shift) {
          isAvailable = "online"
        }
      }
    }
    if ((today == WEEK_OFF && now > EVENING_SHIFT_END_TIME) || (today == (WEEK_OFF + 1) && now < EVENING_SHIFT_END_TIME)) {
      isAvailable = "away"
    }
    if (chef?.isSwitchOff) {
      isAvailable = "offline";
    }
    return isAvailable;
  }

  useEffect(() => {
    const handleKeyboardShow = () => {
      setKeyboardOpen(true);
      scrollToBottom();
    };

    const handleKeyboardHide = () => {
      setKeyboardOpen(false);
    };

    window.addEventListener('resize', handleKeyboardShow);
    window.addEventListener('blur', handleKeyboardHide);

    return () => {
      window.removeEventListener('resize', handleKeyboardShow);
      window.removeEventListener('blur', handleKeyboardHide);
    };
  }, []);

  const scrollToBottom = () => {
    const scrollTo = document.body.scrollHeight - window.innerHeight - 100;
    console.log('scrollTo', scrollTo, document.body.scrollHeight, window.innerHeight)
    window.scrollTo({
      top: scrollTo,
      behavior: 'smooth', // You can use 'auto' for instant scroll
    });
  };

  const onProceedToPay = () => {
    dispatch(cartAction.setInstructionText(instructionText));
    dispatch(bottomBarActions.loadTab({ tabName: "checkout" }));
    mixpanel.track("pay-now-clicked");
    // clevertap.event.push("pay-now-clicked");
    ReactPixel.track("InitiateCheckout");
  }

  useEffect(() => {
    if (isCheckoutCancel) {
      if (searchParams.has("fromScreen")) {
        const fromScreen = searchParams.get("fromScreen");
        if (fromScreen) {
          searchParams.delete("fromScreen");
          setSearchParams(searchParams);
        }
      }
      onProceedToPay()
    }
  }, [isCheckoutCancel])

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const showReferView = () => {
    dispatch(bottomBarActions.hideCart());
    dispatch(referActions.onReferClick({ from: "cart" }));

  };

  useEffect(() => {
    dispatch(loginActions.setSelectedOrder(null));
  }, []);

  const getDeliveryFee = () => {
    const pidgeDeliveryOptions = deliveryOptions?.PIDGE || [];
    const porterDeliveryOptions = deliveryOptions?.PORTER || [];
  
    if (deliveryOptions?.SHADOWFAX?.is_serviceable) {
      // SHADOWFAX delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("SHADOWFAX"));
      return parseInt(deliveryOptions?.SHADOWFAX.total_amount, 10);
    }
  
    if (delivery_distance && isAreaServiceable && porterDeliveryOptions.length) {
      // PORTER delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("PORTER"));
      const number = porterDeliveryOptions[0]?.fare?.minor_amount;
      const formattedNumber = (number / 100).toFixed(2);
      return parseInt(formattedNumber, 10);
    }
    
    if (delivery_distance && isAreaServiceable && pidgeDeliveryOptions.length) {
      // PIDGE delivery available
      dispatch(loginActions.setSelectedDeliveryPortal("PIDGE"));
      const lowestPriceNetwork = pidgeDeliveryOptions.reduce((lowest, current) => {
        return current?.quote?.price < lowest?.quote?.price ? current : lowest;
      })?.quote?.price;
      return parseInt(lowestPriceNetwork, 10);
    }
  
  
    dispatch(loginActions.setSelectedDeliveryPortal(""));
    return 0;
  };


  const getGSTAmount = () => {
    // Calculate 5% GST
    return (parseInt(totalCost) * GST_RATE) / 100;
  };

  const getPackagingAmount = () => {
    let packagingFee = 0;
    cartData.forEach(cart => {
      if (cart.isAddedOnDiscount) {
        packagingFee = packagingFee + cart.packagingCost;
      }
    });
    return packagingFee;
  };

  const goToCheckout = (tabName) => {
    //TO-DO: Restriction - Remove first if for 2 dish restriction
    // if (cartData.length <= maxDishesAllowed && user.maxDishes !== 0) {
    const billingAmount = Math.round(totalCost + getGSTAmount() + getPackagingAmount())
    let deliveryFee = getDeliveryFee();
    if (user.email) {
      const orderType = isAreaServiceable ? "order-now" : "pre-launch";
      dispatch(checkoutUpdate(cartData, user, orderType, deliveryFee, billingAmount, getPackagingAmount(), getGSTAmount()));
    } else {
      switchTab(tabName);
      // }
    }
    mixpanel.track("pay-later-clicked");
    // clevertap.event.push("pay-later-clicked");
    ReactPixel.track("InitiateCheckout");
  };

  const onBackClick = () => {
    isMenuClicked ? switchTab("menu") : switchTab("chef");
  };

  const deliveryInfoText = !isScheduleNow && moment(scheduleDateTime).isValid() ? `Delivery at ${moment(scheduleDateTime).format("MMM D, h:mm a")}` : delivery_Time + " mins to your location";

  useEffect(() => {
    if (prevDeliveryInfoText !== deliveryInfoText) {
      orderPriceIds.current = [];
      dispatch(cartAction.resetTotalCost());
      setPrevDeliveryInfoText(deliveryInfoText)
    }

  }, [deliveryInfoText])

  useEffect(() => {
    if (!isAreaServiceable && user.totalDishesBooked >= MAX_ORDER_DISHES) {
      mixpanel.track("showing error Refer your friends to get 3 more dishes!");
      // clevertap.event.push("showing error Refer your friends to get 3 more dishes!");
    } else if (!isAreaServiceable && user.totalDishesBooked === 0) {
      mixpanel.track("showing error Some Chefs are not online");
      // clevertap.event.push("showing error Some Chefs are not online");
    } else if (isAreaServiceable && user.totalDishesBooked >= MAX_ORDER_DISHES) {
      mixpanel.track("showing error You can schedule an order here.");
      // clevertap.event.push("showing error You can schedule an order here.");
    } else if (totalCost < minimumAmount) {
      mixpanel.track(`showing error Minimum amount should be ${minimumAmount}`);
      // clevertap.event.push(`showing error Minimum amount should be ${minimumAmount}`);
    } else if (kitchenFull) {
      mixpanel.track("showing error Kitchen full, try after some time.");
      // clevertap.event.push("showing error Kitchen full, try after some time.");
    }
  }, [isAreaServiceable, user?.totalDishesBooked, MAX_ORDER_DISHES, totalCost, minimumAmount, kitchenFull]);

  const chefOpenTimeWithDelivery = moment(chefsLatestTime).add(delivery_Time, "minute").subtract({ 'minute': 15 });
  const totalAmountCost = Math.round(totalCost + getGSTAmount() + getDeliveryFee() + getPackagingAmount())
  // const totalAmount = selectedDiscount?.discountid ? countDiscountedAmount(totalAmountCost, selectedDiscount)  :  totalAmountCost
  
  return (
    <AppContainer>
      <div className="cart-wrapper">
        <div className="cart-top-bar">
          <MenuTopBar
            name={isAreaServiceable ? delivery_duration + preparationTime ? deliveryInfoText : "Setup location" : "Pre-order"}
            cuisine={isAreaServiceable ? "Delivering from Marol, Andheri East" : "Your location is out of delivery range"}
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        <section className="section-food-accordian">
          {cartData.length > 0 ? (
            <div className="food-accordian">
              <p className="cart-header">Order Details</p>
              <div className="dish-wrapper-list">
                {cartData.map((dish, i) => {
                  return (
                    <React.Fragment key={dish._id + i}>
                      <Dish
                        key={dish._id + i}
                        id={dish._id}
                        orderPriceIds={orderPriceIds}
                        name={dish.name}
                        category={{ type: dish.categoryType }}
                        price={dish.price}
                        type={dish.type ? dish.type.toUpperCase() : "VEG"}
                        img={
                          dish.img ? S3_MENU_THUMBNAIL_FOLDER + dish.img : ""
                        }
                        mainImg={dish.img ? S3_MENU_FOLDER + dish.img : ""}
                        description={dish.description}
                        fromCart={true}
                        dish={dish}
                        calculateTotal={true}
                        isFromCart={true}
                        parentComponent="cart"
                        isScheduleActive={true}
                        unAvailable={dish.unavailable}
                        isPriceBandVisible={true}
                        isAddedOnDiscount={dish.isAddedOnDiscount}
                        isFromOrderSummary={false}
                        unAvailableChef={unAvailableChef}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          ) : (!isAreaServiceable && user.totalDishesBooked >= MAX_ORDER_DISHES) ? (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>Thank you for pre-ordering.</h5>
                <p>Refer your friends to get 3 more dishes!</p>
                <button onClick={() => showReferView()}>
                  <img src={browseLogo} alt="browseLogo" />
                  Refer
                </button>
              </div>
            </div>
          ) : (!isAreaServiceable && user.totalDishesBooked == 0) ? (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>Pre-order now, Pay later!</h5>
                <p>
                  Currently, no chefs are serving in your location.<br />
                  You can pre-order 2 dishes now and schedule your delivery when chefs start serving in your location or if you're closer to Andheri East.
                </p>

                <button onClick={() => switchTab("chef")}>
                  <img src={browseLogo} alt="browseLogo" />
                  Browse Chefs
                </button>
              </div>
            </div>
          ) : (!isAreaServiceable && user.totalDishesBooked > 0) ? (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>Pre-order now, Pay later!</h5>
                <p>
                  You can pre-order {maxDishesAllowed} more dishes. <br />
                  Schedule your delivery when chefs are serving in your location or if you're closer to Andheri East.
                </p>
                <button onClick={() => switchTab("chef")}>
                  <img src={browseLogo} alt="browseLogo" />
                  Browse Chefs
                </button>
              </div>
            </div>
          ) : (isAreaServiceable && user.totalDishesBooked >= MAX_ORDER_DISHES) ? (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>Fine-dine dishes at local prices</h5>
                <p>Dish prices increase as their popularity increases. <br />
                  Refer friends to get 3 more dishes at deep discounts.</p>
                <button onClick={() => showReferView()}>
                  <img src={browseLogo} alt="browseLogo" />
                  Refer
                </button>
              </div>
            </div>
          ) : (
            <div className="browseDishMain">
              <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                <div className="browseLogo">
                  <div>
                    <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                  </div>
                </div>
              </Suspense>
              <div className="browseDetail">
                <h5>First 2 dishes at 40-99% off!</h5>
                <p>
                  Dish prices increase as their popularity increases.  <br />
                  Since you discovered us early, get 40-99% on your fav dishes.
                </p>
                <button onClick={() => switchTab("chef")}>
                  <img src={browseLogo} alt="browseLogo" />
                  Browse Chefs
                </button>
              </div>
            </div>
          )}
        </section>
      </div>

      {isAreaServiceable && cartData.length != 0 ? (
        <>
          <div className="price-box">
            <div className="price-title">Add cooking instruction</div>
            <textarea value={instructionText} onChange={(e) => {
              setInstructionText(e.target.value)
            }} className="instruction-textarea" />
          </div>
          <div className="billing-section mx-2">
          <div className="billing-title">Apply Coupon</div>
          <div className="discount-container">
              {[...kitchenDiscount, {discounttype: "2", discountid :"111", discount: "20"}].map((discount) => {
                return (
                  <div className="item-price-discount-wrapper">
                    <div className="items-price items item-price-discount-container">
                      <div className="price-title">
                        {+discount.discounttype === 2 ? "Fixed discount" : "Percentage discount"}{" "}
                      </div>
                      <div className="price-value">
                        {" "}
                        &#8377; {discount.discount}{" "}
                      </div>
                      <Button
                        onClick={() =>{
                          const discountObj = {
                            discounttype: discount.discounttype,
                            discountid: discount.discountid,
                            discount: discount.discount,
                          }
                          setSelectedDiscount(discountObj)
                          dispatch(cartAction.setSelectedDiscount(discountObj))
                        }}
                        style={{
                          marginLeft: 6,
                          padding: "4px 6px",
                          backgroundColor: "#f8edd5",
                          color: "#670000",
                        }}
                        className="discount-applied-button"
                        disabled={
                          selectedDiscount?.discountid === discount.discountid
                        }
                      >
                        {selectedDiscount?.discountid === discount.discountid
                          ? "Applied"
                          : "Apply"}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="billing-title">Billing Details</div>
            <div className="price-box">
              <div className="items-price items__first">
                <div className="price-title">Items Price</div>
                <div className="price-value"> &#8377; {parseInt(totalCost)} </div>
              </div>
              <div className="items-price items">
                <div className="price-title">{delivery_duration ? "Delivery Fees (for " + Math.floor(delivery_distance / 1000) + " kms)" : "Delivery Fees"}</div>
                <div className="price-value"> &#8377; {getDeliveryFee()} </div>
              </div>
              <div className="items-price items">
                <div className="price-title">Packaging</div>
                <div className="price-value"> &#8377; {getPackagingAmount()} </div>
              </div>
              <div className="items-price items">
                <div className="price-title">GST</div>
                <div className="price-value"> &#8377; {getGSTAmount()} </div>
              </div>
              <div className="items-price items__total">
                <div className="price-title">Total</div>
                <div className="price-value">
                  {" "}
                  &#8377;{" "}
                  {totalAmountCost}{" "}
                </div>
              </div>
            </div>

            {user.maxDishes == 2 && user.totalDishesBooked < user.maxDishes && (
              <div className="not-serviceble-badge">
                <p>
                  You can order {user.maxDishes - user.totalDishesBooked} dishes
                  at discounted price.
                </p>
              </div>
            )}

            {!user_location ? (
              <div className="select-address_container">
                <div
                  className="add-addres"
                  onClick={() => {
                    dispatch(cartAction.setInstructionText(instructionText));
                    switchTab("locationSelector");
                    dispatch(bottomBarActions.updateFrom({ from: "cart" }));
                    mixpanel.track("cart-setup-location-clicked");
                    // clevertap.event.push("cart-setup-location-clicked");
                  }}
                >
                  <span>SETUP LOCATION</span>
                </div>
              </div>
            ) :
              isAddressOptionsVisible ? (
                <div className="select-address_container">
                  {(addresses.length || (!!selectedAddress && Object.keys(selectedAddress).length !== 0)) ? (
                    <>
                      <div
                        className="add-addres"
                        onClick={() => {
                          dispatch(cartAction.setInstructionText(instructionText));
                          switchTab("addressForm");
                          mixpanel.track("add-address-clicked");
                          // clevertap.event.push("add-address-clicked");
                          dispatch(loginActions.setSelectedOrder(null));
                        }}
                      >
                        <span>ADD ADDRESS</span>
                      </div>
                      <div
                        className="select-address"
                        onClick={() => {
                          dispatch(cartAction.setInstructionText(instructionText));
                          switchTab("address");
                          mixpanel.track("select-address-clicked");
                          // clevertap.event.push("select-address-clicked");
                        }}
                      >
                        <span>SELECT ADDRESS</span>
                      </div>
                    </>
                  ) : (
                    <div
                      className="add-addres"
                      onClick={() => {
                        dispatch(cartAction.setInstructionText(instructionText));
                        switchTab("addressForm");
                        mixpanel.track("add-address-clicked");
                        // clevertap.event.push("add-address-clicked");
                      }}
                    >
                      <span>ADD ADDRESS</span>
                    </div>
                  )}
                </div>
              ) : ((unAvailable || hasDailySpecial) && isScheduleNow) || isSwitchedOffChef ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      if (isSwitchedOffChef) {
                        switchTab("chef");
                        mixpanel.track("chef-unavailable-clicked");
                        // clevertap.event.push("chef-unavailable-clicked");
                      } else {
                        // switchTab("chef");
                        mixpanel.track("schedule-button-clicked");
                        // clevertap.event.push("schedule-button-clicked");
                      }
                    }}
                  >{isSwitchedOffChef ? <span>Some Chefs are not online</span> : <ScheduleOrderButton chefsLatestTime={chefOpenTimeWithDelivery} customButton={<span>You can schedule an order here.</span>} />}
                  </div>
                </div>
              ) : totalCost < minimumAmount ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      dispatch(cartAction.setInstructionText(instructionText));
                      switchTab("chef");
                      mixpanel.track("chef-unavailable-clicked");
                      // clevertap.event.push("chef-unavailable-clicked");
                    }}
                  >
                    <span>Minimum amount should be {minimumAmount}</span>
                  </div>
                </div>
              ) : kitchenFull ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      switchTab("chef");
                      mixpanel.track("chef-unavailable-clicked");
                      // clevertap.event.push("chef-unavailable-clicked");
                    }}
                  >
                    <span>Kitchen full, try after some time.</span>
                  </div>
                </div>
              ) : uniq(allCartChefList.length) > 1 ? (
                <div className="select-address_container">
                  <div
                    className="add-addres"
                    onClick={() => {
                      switchTab("chef");
                      mixpanel.track("chef-unavailable-clicked");
                      // clevertap.event.push("chef-unavailable-clicked");
                    }}
                  >
                    <span>Please order from one restaurant at a time</span>
                  </div>
                </div>
              ) : (
                <div className="pay-box bottom-box">
                  <div className="view-bill">

                    <div>&#8377; {totalAmountCost}{" "} </div>
                    {/* <div>
                  <a className="view-bill-link" href="javacsript:void(0)">
                    Total amount
                  </a>
                </div> */}
                    <ScheduleOrderButton />
                  </div>
                  <div
                    className="pay-bill"
                    onClick={onProceedToPay}
                  >
                    <span>PROCEED TO PAY</span>
                  </div>
                </div>
              )}
            {farAwayModalOnCart &&
              <PopBenifits>
                <div className="modal-top-section">
                  {/* <div onClick={() => setUserBenefits(false)} className="colse-btn">
                <img src={CloseIcon} alt="CloseIcon" />
              </div> */}
                </div>
                <EarlyUsersBoxWrapper>
                  <div className="BenefitsSkimsTitle">
                    <p>
                      You are ordering from a far away location
                    </p>
                  </div>
                  <BenefitsSkims>
                    <div className="SkimItem">
                      <img src="./images/true.png" alt="" />
                      <p>This can affect food quality, temprature and consistency.</p>
                    </div>
                    <div className="SkimItem">
                      <img src="./images/true.png" alt="" />
                      <p>We'll try our best to deliver the best but food always tastes best when hot.</p>
                    </div>
                  </BenefitsSkims>
                  <div className="button-box">
                    <a className="btn-login" onClick={() => {
                      dispatch(loginActions.setFarAwayModalOnCart(false))
                      dispatch(cartAction.setInstructionText(instructionText));
                    }}>
                      <span>Got it</span>
                    </a>
                  </div>
                </EarlyUsersBoxWrapper>
              </PopBenifits>}
          </div>
        </>
      ) : (
        cartData.length != 0 && (
          <>
            <div className="price-box">
              <div className="price-title">Add cooking instruction</div>
              <textarea value={instructionText} onChange={(e) => {
                setInstructionText(e.target.value)
              }} className="instruction-textarea" />
            </div>
            <div className="billing-section mx-2">
              <div className="billing-title">Billing Details</div>
              <div className="price-box">
                <div className="items-price items__first">
                  <div className="price-title">Items Price</div>
                  <div className="price-value">
                    {" "}
                    &#8377; {parseInt(totalCost)}{" "}
                  </div>
                </div>
                <div className="items-price items">
                  <div className="price-title">Delivery Fees</div>
                  <div className="price-value"> &#8377; 0 </div>
                </div>
                <div className="items-price items">
                  <div className="price-title">Packaging</div>
                  <div className="price-value"> &#8377; {getPackagingAmount()} </div>
                </div>
                <div className="items-price items">
                  <div className="price-title">GST</div>
                  <div className="price-value"> &#8377; {getGSTAmount()} </div>
                </div>
                <div className="items-price items__total">
                  <div className="price-title">Total</div>
                  <div className="price-value">
                    {" "}
                    &#8377; {totalAmountCost}{" "}
                  </div>
                </div>
              </div>
              {/* //TO-DO: Restriction - Remove first part for 2 dish restriction */}
              {false ? (
                <>
                  <div className="not-serviceble-badge">
                    <p>You can pre-book a maximum of {maxDishesAllowed} dishes</p>
                  </div>
                  <div className="cart-option bottom-box" id="cart_option">
                    <div className="cart-option-detail pay-box">
                      <a
                        className="menu-link m-auto"
                        onClick={() => showReferView()}
                      >
                        <span>
                          Refer to pre-order more dishes
                        </span>
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="not-serviceble-badge">
                    <p>Your location is not serviceable but you can pre-order</p>
                  </div>
                  <div className="pay-box bottom-box">
                    <div className="view-bill">
                      <div>&#8377; {totalAmountCost}{" "} </div>
                      <div>
                        <a className="view-bill-link" href="javacsript:void(0)">
                          Total amount
                        </a>
                      </div>
                    </div>
                    <div
                      className="pay-bill"
                      onClick={() => {
                        dispatch(cartAction.setInstructionText(instructionText));
                        goToCheckout("checkout")
                      }
                      }
                    >
                      <span>Pre-order now and Pay Later</span>
                    </div>
                    {unserviceableModalOnCart &&
                      <PopBenifits>
                        <div className="modal-top-section">
                          {/* <div onClick={() => setUserBenefits(false)} className="colse-btn">
                        <img src={CloseIcon} alt="CloseIcon" />
                      </div> */}
                        </div>
                        <EarlyUsersBoxWrapper>
                          <div className="BenefitsSkimsTitle">
                            <p>
                              Your location is not in delivery range
                            </p>
                          </div>
                          <BenefitsSkims>
                            <div className="SkimItem">
                              <img src="./images/true.png" alt="" />
                              <p>You can pre-book the order now, no payments required.</p>
                            </div>
                            <div className="SkimItem">
                              <img src="./images/true.png" alt="" />
                              <p>When you're closer to Andheri East, you can schedule the booking</p>
                            </div>
                            <div className="SkimItem">
                              <img src="./images/true.png" alt="" />
                              <p>Or wait for some chefs to setup shop near you!</p>
                            </div>
                          </BenefitsSkims>
                          <div className="button-box">
                            <a className="btn-login" onClick={() => dispatch(loginActions.setUnserviceableModalOnCart(false))}>
                              <span>Got it</span>
                            </a>
                          </div>
                        </EarlyUsersBoxWrapper>
                      </PopBenifits>}
                  </div>
                </>
              )}
            </div>
          </>
        )
      )}
      {cartData.length == 0 && <BottomBar />}
      <ToastContainer limit={1} />
    </AppContainer>
  );
};
export default Cart;
